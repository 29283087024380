import { Typography, Stack, IconButton, alpha, Snackbar, Alert, Box, Popover } from '@mui/material';
import { t } from 'i18next';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import shoppingCartSlice from '../../../../services/shoppingCart';
import greyScale from '../../../../themes/default/colors/greyscale';
import palette from '../../../../themes/default/colors/palette';
import { computeApplyPromoCode, computeDiscount } from '../../../../utils/computeDiscount';
import PromoChipTag from '../PromoChipTag';
import { TicketCategory } from '../../../../services/api/ticket-category';
import { RootState } from '../../../../redux/store';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Info } from '@mui/icons-material';
import { Row } from '@/components/ui/row';

interface RankProps {
  subTicketCategory: TicketCategory;
  eventId: string;
  categoryName: string;
  isAvailable: boolean;
  hasSubCategories: boolean;
  type: 'subscription' | 'event';
  showDescription?: boolean;
}

const TicketRank = (props: RankProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const dispatch = useAppDispatch();
  const { tickets: selectedTickets, promo: promos } = useAppSelector((app: RootState) => app.shoppingCartSlice);
  const { eventId, subTicketCategory, categoryName, isAvailable, type } = props;
  const promo = promos?.find(p => p.ticketCategories.find(t => t.ticketCategoryId === subTicketCategory.id));
  const canApplyPromoCode = computeApplyPromoCode(subTicketCategory.id, promo);
  const finalPrice = computeDiscount(subTicketCategory.price, promo);

  const initializeQuantity = () => {
    if (selectedTickets.filter(e => e.categoryUuid === subTicketCategory.uuid)[0]?.quantity) {
      setQuantity(selectedTickets.filter(e => e.categoryUuid === subTicketCategory.uuid)[0]?.quantity);
    } else {
      setQuantity(0);
    }
  };

  useEffect(() => {
    initializeQuantity();
  }, []);

  const addTicket = () => {
    if (
      selectedTickets.filter(e => e.categoryUuid === subTicketCategory.uuid)[0]?.quantity >=
      subTicketCategory.quantityAvailable
    ) {
      setErrorMessage(`Il ne reste plus que ${subTicketCategory.quantityAvailable} places disponibles`);
      setErrorSnackbar(true);

      return;
    }
    if (
      selectedTickets.filter(e => e.categoryUuid === subTicketCategory.uuid)[0]?.quantity >=
        subTicketCategory.maxPerPerson &&
      subTicketCategory.maxPerPerson !== null
    ) {
      setErrorMessage(`Vous pouvez acheter au maximum ${subTicketCategory.maxPerPerson} places par personne`);
      setErrorSnackbar(true);

      return;
    }

    dispatch(
      shoppingCartSlice.actions.addTicket({
        eventId,
        ticketCategory: { ...subTicketCategory, name: categoryName },
        promo: canApplyPromoCode ? promo : undefined,
        type: type
      })
    );
    setQuantity(quantity + 1);
  };

  const removeTicket = () => {
    if (quantity === 0) return;
    dispatch(
      shoppingCartSlice.actions.removeTicket({ eventId, ticketCategory: { ...subTicketCategory, name: categoryName } })
    );
    setQuantity(quantity - 1);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const ticketLeft = () => {
    if (subTicketCategory.quantityAvailable > 5) {
      return null;
    } else if (subTicketCategory.quantityAvailable == 0) {
      return null;
    } else {
      return (
        <Typography variant="md" color="error.main">{`(${subTicketCategory.quantityAvailable} ${t('reservation.left', {
          count: subTicketCategory.quantityAvailable
        })})`}</Typography>
      );
    }
  };

  const selectTicketButtons = () => {
    if (subTicketCategory.quantityAvailable > 0) {
      return (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <IconButton
            onClick={removeTicket}
            disabled={quantity === 0}
            sx={{
              border: 2,
              height: 40,
              width: 40,
              borderRadius: 16,
              // backgroundColor: quantity === 0 ? '' : palette.dark2,
              borderColor: greyScale[800]
            }}
          >
            <RemoveIcon />
          </IconButton>
          <Typography variant="h6" p={1} pr={2.5} textAlign="center" justifyContent="center">
            {quantity}
          </Typography>
          <IconButton
            onClick={addTicket}
            sx={{
              border: 2,
              height: 40,
              width: 40,
              borderRadius: 16,
              // backgroundColor: palette.dark2,
              borderColor: greyScale[800]
            }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
      );
    } else {
      return (
        <Stack direction="column" alignItems="end">
          <Box bgcolor={alpha(palette.error, 0.12)} borderRadius={1} height={24} pr={2} pl={2}>
            <Typography variant="xs" color="error.main" fontWeight={700}>
              {t('reservation.notAvailable')}
            </Typography>
          </Box>
        </Stack>
      );
    }
  };

  const priceDisplay = () => {
    if (!canApplyPromoCode) {
      return (
        <Stack direction="row" alignItems="center">
          <Typography variant="xl" fontWeight={700} color={greyScale[400]}>{`${Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
          }).format(subTicketCategory.price)}`}</Typography>
        </Stack>
      );
    } else if (canApplyPromoCode && promo?.discountType === 'FIXED_AMOUNT_OFF') {
      return (
        <Stack direction="row" alignItems="start">
          <Stack direction="column" alignItems="start">
            <Typography
              variant="lg"
              fontWeight={600}
              color={greyScale[600]}
              sx={{
                textDecoration: 'line-through'
              }}
            >{`${Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(subTicketCategory.price)}`}</Typography>
            <Typography variant="xl" fontWeight={600} mt={1} color={greyScale[400]}>{`${Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(finalPrice)}`}</Typography>
          </Stack>
          &nbsp;&nbsp;
          <PromoChipTag promo={promo} srcPrice={subTicketCategory.price} />
        </Stack>
      );
    } else if (canApplyPromoCode && promo?.discountType === 'PERCENTAGE_OFF') {
      return (
        <Stack direction="row" alignItems="start">
          <Stack direction="column" alignItems="start">
            <Typography
              variant="lg"
              fontWeight={600}
              color={greyScale[600]}
              sx={{
                textDecoration: 'line-through'
              }}
            >{`${Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(subTicketCategory.price)}`}</Typography>{' '}
            <Typography variant="xl" fontWeight={600} mt={1} color={greyScale[400]}>{`${Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(finalPrice)}`}</Typography>
          </Stack>
          &nbsp;&nbsp;
          <PromoChipTag promo={promo} srcPrice={subTicketCategory.price} />
        </Stack>
      );
    } else {
      return (
        <Typography variant="xl" fontWeight={600} color={greyScale[400]}>{`${Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR'
        }).format(subTicketCategory.price)}`}</Typography>
      );
    }
  };

  return (
    <>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>{' '}
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" className="w-full mt-[2px]">
          <Row className="w-full">
            {props.hasSubCategories && (
              <Typography variant="h5" color={greyScale[700]}>
                {subTicketCategory.name}
              </Typography>
            )}
          </Row>
          {props.showDescription && (
            <Typography variant="md" color={greyScale[500]} my={0.5}>
              {subTicketCategory.description}
            </Typography>
          )}
          <Row className="w-full justify-between">
            <Stack direction="row" alignItems="end" mt={1}>
              {priceDisplay()}
              &nbsp;&nbsp;
              {ticketLeft()}
            </Stack>
            <div className="flex items-end">{selectTicketButtons()}</div>
          </Row>
        </Stack>
      </Stack>
    </>
  );
};

export default TicketRank;
