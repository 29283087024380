import { Outlet } from 'react-router-dom';
import TickieBottomNavigation from './components/BottomNavigation';
import Footer from './components/Footer';
import { getWebsiteConfig } from './pages/whiteLabel/themeFactory';
import CustomFooter from './components/CustomFooter ';

const footer = () => {
  const id = getWebsiteConfig().id;
  if (id === 'TICKIE') {
    return <Footer />;
  } else if (id === 'BLAGNAC') {
    return <CustomFooter />;
  } else {
    return <Footer />;
  }
};

const Root = () => {
  return (
    <>
      <Outlet />
      {footer()}
      <TickieBottomNavigation />
    </>
  );
};

export default Root;
