import { Column } from '@/components/ui/column';
import { Row } from '@/components/ui/row';
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import TicketCategoryComponent from '../TicketCategoryComponent';
import ticketCategoriesApi from '@/services/api/ticket-category';
import { useParams, useSearchParams } from 'react-router-dom';
import { Event } from '@/services/api/event';
import { t } from 'i18next';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import { useState } from 'react';
import TabPanel from '@/components/TabPanel';

interface SelectTicketsCardProps {
  event: Event;
}

const SelectTicketsCard = ({ event }: SelectTicketsCardProps) => {
  const { eventId } = useParams();
  let [urlParams, setUrlParams] = useSearchParams();
  const promoCodeFromUrl = urlParams.get('promoCode');
  const [tab, setTab] = useState(0);

  const { data: ticketCategories } = ticketCategoriesApi.endpoints.getTicketCategories.useQuery(
    { eventUuid: eventId!, promoCode: promoCodeFromUrl ?? undefined },
    { skip: !eventId }
  );
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const categorySelect = () =>
    ticketCategories && ticketCategories?.length > 0 ? (
      ticketCategories?.map((category, i) => (
        <Box
          key={category.name}
          sx={{
            '&:hover:not(.Mui-disabled)': {
              cursor: 'pointer'
            }
          }}
        >
          <TicketCategoryComponent
            category={category}
            eventId={eventId ?? ''}
            isAvailable={event.isAvailable}
            type="event"
          />
          {ticketCategories?.length === i + 1 ? null : (
            <Box mt={1} mb={1}>
              <Divider />
            </Box>
          )}
        </Box>
      ))
    ) : (
      <Typography variant="h4" textAlign="center">
        {t('reservation.noTicketsAvailable')}
      </Typography>
    );

  const seatSelect = () => (
    <div>
      <SeatsioSeatingChart
        workspaceKey="6e45980d-438f-4dbd-b6a1-dc0286eb3616"
        event="bb4e7a38-0106-4a23-aaf3-3b9cbfd4fd6d"
        region="eu"
      />
    </div>
  );

  return (
    <Column className={`checkout-card p-4  w-full max-w-full h-full`}>
      <Typography variant="h5">Sélectionnez vos billets</Typography>
      <Typography variant="caption">TVA 5,5% incluse</Typography>
      <Row className="h-8" />

      {event.useSeatsIoManager ? (
        <>
          <Box alignItems="center" justifyContent="center" width="100%" display="flex" mt={-3} mb={6}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              className="w-full"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label={'Plan de salle'} className="w-1/2 max-w-full" />
              <Tab label={'Meilleurs places disponibles'} className="w-1/2 text-center max-w-full" />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            {seatSelect()}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {categorySelect()}
          </TabPanel>
        </>
      ) : (
        categorySelect()
      )}
    </Column>
  );
};
export default SelectTicketsCard;
