import { Box, Button, CircularProgress, Grid, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import UpcomingEventsCard from '../../components/UpcomingEventsCard';
import ticketApi from '../../services/api/ticket';
import DesktopDrawer from '../../components/DesktopDrawer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Login from '../Login';
import LogoButton from '../../components/LogoButton';
import UnhandledError from '../../components/UnhandledError';
import noTicketsImage from '/Frame.svg';
import subscriptionApi from '../../services/api/subscription';
import UserSubscriptionCard from '../../components/UserSubscriptionCard';
import { useNavigate } from 'react-router-dom';
import greyScale from '../../themes/default/colors/greyscale';
import TabPanel from '../../components/TabPanel';

const MyTickets = () => {
  const [tab, setTab] = useState(0);

  const {
    data: ticketsEvent,
    isLoading,
    isError
  } = ticketApi.endpoints.getTicketsEvent.useQuery(undefined, { refetchOnMountOrArgChange: true });

  const {
    data: userSubscriptions,
    isLoading: userSubscriptionsIsLoading,
    isError: userSubscriptionsIsError
  } = subscriptionApi.endpoints.getUserSubscriptions.useQuery(undefined, { refetchOnMountOrArgChange: true });

  const [triggerFindPendingReservation, { data: pendingReservation }] =
    ticketApi.endpoints.findPendingReservation.useLazyQuery(undefined);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuth } = useAppSelector(state => state.authSlice);
  const [isPolling, setIsPolling] = useState(false);

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(() => {
        triggerFindPendingReservation();
        dispatch(ticketApi.util.invalidateTags(['tickets']));
        // dispatch(subscriptionApi.util.invalidateTags(['user-subscriptions']));
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [isPolling, dispatch]);

  useEffect(() => {
    setIsPolling(true);

    setTimeout(() => {
      setIsPolling(false);
    }, 20000);
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (!isAuth) {
    return <Login />;
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`100vh`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError || userSubscriptionsIsError) {
    return <UnhandledError />;
  }

  const appBar = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} m={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <LogoButton />
          <Typography variant="h5">{t('myTickets.myTickets')}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          {/* <SearchIcon />
          <MoreHorizIcon /> */}
          <DesktopDrawer />
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      {appBar()}
      {(pendingReservation && pendingReservation?.length > 0) || (ticketsEvent && ticketsEvent?.length > 0) ? (
        <Box minHeight="100vh" m={3}>
          <Box alignItems="center" justifyContent="center" width="100%" display="flex" mt={-3} mb={6}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              className="w-full lg:w-fit"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label={t('myTickets.toCome')} className="w-1/3 lg:w-fit " />
              <Tab label={t('myTickets.pending')} className="w-1/3 lg:w-fit" />
              <Tab label={t('myTickets.toSell')} className="w-1/3 lg:w-fit " />
            </Tabs>
          </Box>
          <Grid container spacing={2}>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                {ticketsEvent?.map(event => (
                  <Grid
                    key={event.uuid}
                    item
                    xs={50}
                    sm={7}
                    md={4}
                    xl={3}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: { xl: '0', md: 'auto' }
                    }}
                  >
                    <UpcomingEventsCard event={event} />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2} mt={3}>
                {userSubscriptions?.map(sub => (
                  <Grid
                    key={sub.uuid}
                    item
                    xs={50}
                    sm={7}
                    md={5}
                    xl={3}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: { xl: '0', md: 'auto' }
                    }}
                  >
                    <UserSubscriptionCard userSubscription={sub} />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          </Grid>
        </Box>
      ) : (
        <NoTickets />
      )}
    </>
  );
};

const NoTickets = () => {
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      {/* <img alt="no-tickets" src={noTicketsImage} /> */}
      <Box
        height={210}
        width={150}
        borderRadius={2}
        sx={{ background: 'linear-gradient(48deg, #27D4FF 2.24%, #584CF4 92.5%)' }}
      />
      <Typography variant="h4" fontWeight={600} mt={2}>
        Aucun billet
      </Typography>

      <br />
      <Typography variant="lg" mt={2}>
        Il semble que vous n'ayez pas encore de billet.{' '}
      </Typography>
      <br />
      <Typography variant="lg" mt={2} textAlign={'center'}>
        Commencez à rechercher des événements <br /> en cliquant sur le bouton ci-dessous 👇{' '}
      </Typography>
      <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => navigate('/')}>
        Rechercher un événement
      </Button>
      <Link href="https://tally.so/r/3X5gvO" target="_blank" sx={{ color: greyScale[500] }}>
        Je ne visualise pas mes billets
      </Link>
      <br />
      <br />
      <br />
    </Box>
  );
};

export default MyTickets;
