import { useNavigate } from 'react-router-dom';
import { HomeEvent } from '../../services/api/event';
import { Button } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { isBefore } from 'date-fns';
import TicketIcon from '../../assets/images/icons/ticket';
import { getWebsiteConfig } from '@/pages/whiteLabel/themeFactory';

interface OnDispayEventCardProps {
  event: HomeEvent;
  isEvent?: boolean;
}

const CustomOnDispayEventCardButton = (props: OnDispayEventCardProps) => {
  const { event } = props;
  const navigate = useNavigate();
  const websiteId = getWebsiteConfig()?.id;

  const isEventAvailable = (event: HomeEvent) => {
    if (event.saleBeginAt && isBefore(new Date(), new Date(event.saleBeginAt))) {
      return false;
    } else if (event.saleEndAt && isBefore(new Date(event.saleEndAt), new Date())) {
      return false;
    } else {
      return true;
    }
  };

  const isEventSoldOut = event.remainingTickets == 0;

  if (isEventSoldOut) {
    return (
      <Button
        fullWidth
        disabled
        className="h-[54px] shadow-lg text-white bg-gradient-to-r from-[#909090] via-[#909090]  to-[#DBDBDB]"
      >
        Sold out
        <Lock sx={{ width: 16, height: 16, ml: 1 }} />
      </Button>
    );
  } else if (!isEventAvailable(event)) {
    return (
      <Button
        fullWidth
        disabled
        className="h-[54px] shadow-lg text-white bg-gradient-to-r from-[#909090] via-[#909090]  to-[#DBDBDB]"
      >
        Vente fermée
        <Lock sx={{ width: 16, height: 16, ml: 1 }} />
      </Button>
    );
  } else {
    return (
      <div
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          navigate(props.isEvent ? `/event/${event.uuid}` : `/claim/${event.uuid}`);
        }}
        className={`text-white w-full h-[54px] flex justify-center rounded-md items-center ${
          websiteId === 'SAINTES' ? 'bg-orange-600' : websiteId === 'GRIZZLYS' ? 'bg-[#D6BF91]' : 'bg-red-600'
        } font-bold cursor-pointer z-50`}
      >
        Choisir ma place
        <TicketIcon size="17" className="ml-5 w-[17px]" />
      </div>
    );
  }
};

export default CustomOnDispayEventCardButton;
