import { useMediaQuery } from '@mui/material';
import { Tilt } from 'react-tilt';

interface ResponsiveTiltProps {
  children?: React.ReactNode;
}

const ResponsiveTilt = (props: ResponsiveTiltProps) => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  if (isDesktop) {
    return <Tilt options={{ scale: 1, perspective: 1800, max: 12 }}>{props.children}</Tilt>;
  } else {
    return <>{props.children}</>;
  }
};

export default ResponsiveTilt;
