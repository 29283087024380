import { Box, Stack, Typography } from '@mui/material';
import greyScale from '../../../../themes/default/colors/greyscale';
import { useNavigate } from 'react-router-dom';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NftSeasonCard from '../NftSeaonCard';
import { Season } from '../../../../services/api/season';
import { Ticket } from '../../../../services/api/ticket';
import { getWebsiteConfig } from '../../../whiteLabel/themeFactory';
import { filterEvents } from '../../../../utils/seasonUtils';

interface SeasonCardProps {
  isCollectible?: boolean;
  bgImage: string;
  season: Season;
  tickets: Ticket[];
}

const SeasonCard = (props: SeasonCardProps) => {
  const { tickets, season } = props;

  const navigate = useNavigate();
  const logo = getWebsiteConfig().logo;

  return (
    <Box
      onClick={() => navigate(`/pitch/${season.uuid}`)}
      mb={1}
      mt={1}
      p={2}
      pr={0}
      width="100%"
      maxHeight={220}
      className="cursor-pointer transform transition duration-500 hover:scale-[101%] scale-100 hover:shadow-2xl  "
      borderRadius={4}
      sx={{
        backgroundImage: `url(/images/seasons-cards-bg/${props.bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <Stack direction={props.isCollectible ? 'row' : 'column'}>
        <Stack
          direction={props.isCollectible ? 'column' : 'row'}
          width="100%"
          justifyContent="space-between"
          overflow="hidden"
          alignItems={props.isCollectible ? undefined : 'center'}
        >
          <Stack direction="row" alignItems="end" spacing={2} width="100%">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: greyScale[900],
                borderRadius: 1000,
                minWidth: 40,
                minHeight: 40
              }}
            >
              {props.isCollectible ? (
                <EmojiEventsIcon />
              ) : (
                <img
                  alt="Remy Sharp"
                  src={
                    logo ??
                    'https://upload.wikimedia.org/wikipedia/fr/thumb/3/39/Logo_Blagnac_Rugby_-_2022.svg/1200px-Logo_Blagnac_Rugby_-_2022.svg.png'
                  }
                  style={{ objectFit: 'contain' }}
                  height={24}
                  width={24}
                />
              )}
            </Box>
            <Stack direction="column" overflow="hidden">
              <Typography variant="xl" fontWeight={700} noWrap textOverflow="ellipsis" overflow="hidden">
                {' '}
                {season.name}
              </Typography>
              <Typography variant="sm" noWrap fontWeight={600} sx={{ color: 'transparent' }}>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: props.season
                  }}
                /> */}
                card
              </Typography>
            </Stack>
          </Stack>
          <Typography
            variant="xl"
            textOverflow="ellipsis"
            overflow="hidden"
            color={greyScale[200]}
            textAlign="end"
            width="100%"
            px={2}
          >
            <span style={{ fontWeight: 600 }}>{season.numberOfEvents}</span>
            &nbsp;
            {props.isCollectible ? 'Cartes' : 'Événements'}
          </Typography>
        </Stack>
        {props.isCollectible ? null : (
          <Stack direction="row" pl={1} width="100%" pt={1} sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <div className="flex flex-row items-center justify-star space-x-2">
              {filterEvents(season, tickets).map((element, index) => (
                <NftSeasonCard pitchNft={element} />
              ))}
            </div>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default SeasonCard;
