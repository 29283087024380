import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { t } from 'i18next';
import { Avatar, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import transparent from '../../themes/default/colors/transparent';
import greyScale from '../../themes/default/colors/greyscale';
import { useAppSelector } from '../../redux/hooks';
import userApi from '../../services/api/user';
import React from 'react';
import whiteLabelConfig from '../../config/whiteLabelConfig';
import { getWebsiteConfig } from '../../pages/whiteLabel/themeFactory';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    minWidth: 240,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      height: 46,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

export default function DesktopDrawer() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { isAuth } = useAppSelector(state => state.authSlice);
  const { data: user } = userApi.endpoints.getUser.useQuery();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const appId = getWebsiteConfig()?.id;

  const logedInMenu = [
    {
      icon: <HomeOutlinedIcon />,
      title: t('navigationBar.home'),
      path: '/home'
    },
    // {
    //   icon: <StorefrontOutlinedIcon />,
    //   title: t('navigationBar.marketplace'),
    //   path: '/marketplace'
    // },
    {
      icon: <ConfirmationNumberOutlinedIcon />,
      title: t('navigationBar.tickets'),
      path: '/my-tickets'
    },
    {
      icon: null,
      title: '',
      path: null
    },
    // {
    //   icon: <NotificationsNoneOutlinedIcon />,
    //   title: t('navigationBar.messages'),
    //   path: '/messages'
    // },
    {
      icon: <PersonOutlineOutlinedIcon />,
      title: t('navigationBar.profile'),
      path: '/profile'
    }
  ];

  const logedOutMenu = [
    {
      title: t('navigationBar.signIn'),
      path: '/login'
    },
    {
      title: t('navigationBar.signUp'),
      path: '/register'
    }
  ];

  return (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      <Button
        variant="contained"
        disableElevation
        sx={{
          backgroundColor: 'white',
          padding: 1,
          height: 42,
          '&:hover': { backgroundColor: transparent.purple },
          border: `1px solid ${greyScale[300]}`
        }}
        onClick={handleClick}
      >
        <MenuRoundedIcon sx={{ color: 'black', mr: 1 }} />

        {isAuth && user ? (
          <Avatar src={user?.avatar} sx={{ width: 24, height: 24 }} />
        ) : (
          <AccountCircleRoundedIcon sx={{ color: 'black' }} />
        )}
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {!isAuth
          ? logedOutMenu.map((e, index) => (
              <MenuItem
                key={`drawer-menu-item-${index}`}
                onClick={() => {
                  handleClose();
                  navigate(e.path ?? '');
                }}
                disableRipple
              >
                <Typography fontWeight={600}> {e.title}</Typography>
              </MenuItem>
            ))
          : logedInMenu.map((e, index) =>
              e.path == null ? (
                <Divider key={`drawer-menu-item-${index}`} />
              ) : (
                <MenuItem
                  key={`drawer-menu-item-${index}`}
                  onClick={() => {
                    handleClose();
                    navigate(e.path ?? '');
                  }}
                  disableRipple
                >
                  {e.icon}
                  <Typography fontWeight={600}> {e.title}</Typography>
                </MenuItem>
              )
            )}
        {(import.meta.env.VITE_NODE_ENV === 'development' || import.meta.env.VITE_NODE_ENV === 'staging') && (
          <>
            <Divider key={`drawer-menu-item-clients`} />
            {Object.entries(whiteLabelConfig)
              .filter(e => e)
              .map(([key, value]) => (
                <MenuItem
                  key={`drawer-menu-item-10` + key}
                  selected={localStorage.getItem('currentClient') === key}
                  onClick={() => {
                    localStorage.setItem('currentClient', key);
                    window.location.reload();
                  }}
                  disableRipple
                >
                  <img src={value.logo} width={24} />
                  <Typography fontWeight={600} ml={1}>
                    {' '}
                    {value.name}
                  </Typography>
                </MenuItem>
              ))}
          </>
        )}
      </StyledMenu>
    </Box>
  );
}
