import { Outlet } from 'react-router-dom';
import TickieBottomNavigation from './components/BottomNavigation';

const RootWithoutFooter = () => {
  return (
    <>
      <Outlet />
      <TickieBottomNavigation />
    </>
  );
};

export default RootWithoutFooter;
