import { Box, Button, useMediaQuery } from '@mui/material';
import { isAfter, isBefore } from 'date-fns';
import { Event } from '../../../../services/api/event';
import { useNavigate, useSearchParams } from 'react-router-dom';
import shadows from '../../../../themes/default/shadows';
import { Lock } from '@mui/icons-material';
import { t } from 'i18next';

interface BuyButtonProps {
  event: Event | undefined;
}

const BuyButton = ({ event }: BuyButtonProps) => {
  let [urlParams, setUrlParams] = useSearchParams();
  const promoCode = urlParams.get('promoCode');
  const navigate = useNavigate();

  const isEventAvailable = () => {
    if (!event) return;
    if (event?.saleBeginAt && isBefore(new Date(), new Date(event?.saleBeginAt))) {
      return false;
    } else if (event.saleEndAt && isBefore(new Date(event.saleEndAt), new Date())) {
      return false;
    } else {
      return true;
    }
  };

  const isEventSoldOut = event?.remainingTickets == 0;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  if (isEventSoldOut) {
    return (
      <Box m={isDesktop ? 3 : 1.5}>
        <Button
          fullWidth
          disabled
          className="h-[58px] shadow-lg text-white bg-gradient-to-r from-[#909090] via-[#909090]  to-[#DBDBDB]"
        >
          Sold out
          <Lock sx={{ width: 16, height: 16, ml: 1 }} />
        </Button>
      </Box>
    );
  } else if (!isEventAvailable()) {
    return (
      <Box m={isDesktop ? 3 : 1.5}>
        <Button
          fullWidth
          disabled
          className=" h-[58px] shadow-lg text-white bg-gradient-to-r from-[#909090] via-[#909090]  to-[#DBDBDB]"
        >
          Vente fermée
          <Lock sx={{ width: 16, height: 16, ml: 1 }} />
        </Button>
      </Box>
    );
  } else {
    return (
      <Box m={isDesktop ? 3 : 1.5}>
        <Button
          variant={isAfter(new Date(), new Date(event!.endAt)) ? 'contained' : 'gradient'}
          fullWidth
          disabled={isAfter(new Date(), new Date(event!.endAt))}
          onClick={() => {
            navigate(`/reservation/${event?.uuid}${promoCode ? `?promoCode=${promoCode}` : ''}`);
          }}
          sx={{
            boxShadow: shadows.primaryButton,
            height: 58,
            fontSize: 18,
            borderRadius: 2
          }}
        >
          {t('event.order')}
        </Button>
      </Box>
    );
  }
};

export default BuyButton;
