import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

interface VerticalCardProps {
  title: string;
  text1: string;
  text2: string;
  image: string;
  buttonText: string;
  index: number;
  link: string;
  onClick: any;
}

const VerticalCard: React.FC<VerticalCardProps> = ({
  title,
  text1,
  text2,
  image,
  index,
  buttonText,
  link,
  onClick
}) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  return (
    <Box
      width={'100%'}
      height={'100%'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        my: '20px',
        alignItems: 'center'
      }}
    >
      <Box
        height={'100%'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '95%',
          marginX: isSmall ? '10px' : '0px',
          borderRadius: '35px',
          border: '1px solid #2A2A2A',
          padding: isSmall ? '30px' : '10px',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
          }}
        >
          <Box
            width="95%"
            height={'260px'}
            sx={{
              borderRadius: '35px 35px 0 0 ',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover'
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: isSmall ? '20px' : '5px',
            flexDirection: 'column',
            alignItems: 'space-between  '
          }}
        >
          <Box>
            <Typography
              className="custom-font-text"
              sx={{
                fontSize: '31px ',
                color: '#777'
              }}
            >
              0{index}
            </Typography>
            <Typography
              className="custom-font-text"
              sx={{
                fontSize: isDesktop ? '30px' : '20px',
                color: 'white',
                textTransform: 'uppercase',
                whiteSpace: 'pre-line'
              }}
            >
              {title}{' '}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'helvetica ',

                fontSize: isSmall ? '21px ' : '18px',
                color: '#777',
                whiteSpace: 'pre-line'
              }}
            >
              {text1} <br />
              <br /> {text2}{' '}
            </Typography>
          </Box>

          <br />

          {/* <Box
            className="custom-font-text hover:cursor-pointer"
            sx={{
              margin: !isDesktop ? 'auto' : 'unset',
              background: '#212121',
              border: '2px solid #454545',
              borderRadius: '30px',
              fontWeight: '800',
              color: '#FFF',
              width: '250px',
              height: '55px',
              minWidth: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                background: '#454545'
              }
            }}
            onClick={onClick}>
            {buttonText}{' '}
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default VerticalCard;
