import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Stack } from '@mui/system';
import { t, use } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
// import 'add-to-calendar-button/assets/css/atcb.css';
import palette from '../../themes/default/colors/palette';
import greyScale from '../../themes/default/colors/greyscale';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import ticketApi, { Ticket } from '../../services/api/ticket';
import RevealModal from './RevealModal';
import UnhandledError from '../../components/UnhandledError';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import noReveal from '../../assets/images/card/no-reveal.png';
import { Variants, motion, useAnimation } from 'framer-motion';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { authSlice } from '../../services/auth';
import eventApi, { Event } from '../../services/api/event';
import NoTransferModal from './NoTransferModal';
import ResponsiveTilt from '../../components/ResponsiveTilt';
import useTokenUri from '../../hooks/useTokenUri';
import { getWebsiteConfig } from '../whiteLabel/themeFactory';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DetailsCard from './DetailsCard';
import CategoryCard from './CategoryCard';
import { Person, Sell } from '@mui/icons-material';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import NorthRoundedIcon from '@mui/icons-material/NorthRounded';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { Mail } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

import { useTheme as useShadCnTheme } from '@/components/theme-provider';
interface NftCardProps {
  ticket: Ticket;
  event: Event;
  open: any;
  isActive: boolean;
}

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <Stack
      display="block"
      bottom="-13%"
      right={24}
      position="absolute"
      sx={{ zIndex: 300, transform: 'translateY(-50%)' }}
    >
      <IconButton onClick={onClick} sx={{ backgroundColor: palette.dark3 }}>
        <NavigateNextIcon sx={{ color: '#FFF', width: 20, height: 20 }}></NavigateNextIcon>
      </IconButton>
    </Stack>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <Stack
      display="block"
      bottom="-13%"
      position="absolute"
      left={24}
      sx={{ transform: 'translate(0, -50%)', zIndex: 300 }}
      onClick={onClick}
    >
      <IconButton onClick={onClick} sx={{ backgroundColor: palette.dark3 }}>
        <NavigateBeforeIcon sx={{ color: '#FFF', width: 20, height: 20 }}></NavigateBeforeIcon>
      </IconButton>
    </Stack>
  );
}

const getRandomTransformOrigin = () => {
  const value = (16 + 40 * Math.random()) / 100;
  const value2 = (15 + 36 * Math.random()) / 100;
  return {
    originX: value,
    originY: value2
  };
};

const variants: Variants = {
  start: (i: number) => ({
    rotate: [0, 2, 0, -2, 0],
    transition: {
      delay: 0.5,
      repeat: 1,
      duration: 0.8,
      bounce: 0.5,
      power: 0.5
    }
  }),
  reset: {
    rotate: 0
    // x: 0
  }
};

const NftCard = (props: NftCardProps) => {
  const { nftImage } = useTokenUri({ ticket: props.ticket });
  const controls = useAnimation();

  useEffect(() => {
    if (!props.ticket.isOpened && props.ticket.nftId !== null) {
      controls.start('start');
    } else {
      controls.start('reset');
    }
  }, [props.ticket, controls]);

  useEffect(() => {
    // every 5 seconds
    const interval = setInterval(() => {
      controls.start('start');
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {props.ticket.isOpened ? (
        <>
          <ResponsiveTilt>
            <Stack alignItems="center" justifyContent="center" height={330} m={3} borderRadius={8}>
              <motion.div
                whileHover={{ scale: 1.06 }}
                whileTap={{ scale: 0.9 }}
                // onClick={onClick}
                className="nft-image-card"
              >
                <motion.img
                  src={nftImage}
                  height={330}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: 'spring', stiffness: 300, bounce: 0.35 }}
                />
              </motion.div>
            </Stack>
          </ResponsiveTilt>
        </>
      ) : (
        <Stack alignItems="center" justifyContent="center">
          <ResponsiveTilt>
            <motion.div
              // style={{
              //   transformOrigin: getRandomTransformOrigin(),
              // }}
              style={{
                ...getRandomTransformOrigin()
              }}
              // -- controls --
              custom={0}
              variants={variants}
              animate={controls}
            >
              <motion.div
                whileHover={{ scale: 1.06 }}
                whileTap={{ scale: 0.9 }}
                // onClick={onClick}
                className="nft-image-card"
              >
                <Stack
                  alignItems="center"
                  justifyContent="end"
                  height={330}
                  width={250}
                  m={3}
                  sx={{ backgroundImage: `url(${noReveal})`, backgroundSize: 'cover' }}
                >
                  {props.ticket.nftId !== null ? (
                    <Button variant="gradient" sx={{ borderRadius: 100, mb: 3, height: 46 }} onClick={props.open}>
                      {t('myTickets.discoverTicket')}
                    </Button>
                  ) : (
                    <Button
                      disabled
                      variant="gradient"
                      sx={{ borderRadius: 100, mb: 3, height: 46 }}
                      onClick={props.open}
                    >
                      {t('myTickets.discoverTicket')}
                      <CircularProgress sx={{ color: '#FFF', ml: 1 }} size={24} />
                    </Button>
                  )}
                </Stack>
              </motion.div>
            </motion.div>
          </ResponsiveTilt>
          <Typography></Typography>
        </Stack>
      )}
    </div>
  );
};

function TicketDetails() {
  const { eventId } = useParams();
  const { data: event } = eventApi.endpoints.getEventDetails.useQuery(eventId ?? '');
  const [openRevealModal, setRevealModal] = useState(false);
  const mapRef = useRef<null | HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimationOpen, setAnimationOpen] = useState(false);
  const [openTicket] = ticketApi.endpoints.open.useMutation();
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sliderRef = useRef<Slider>(null);
  const [transferModal, setTransferModal] = useState(false);
  const [isTransfert, setIsTransfert] = useState(false);
  const { isAuth } = useAppSelector(state => state.authSlice);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [showTransfertPage, setShowTransfertPage] = useState(false);
  const {
    data: tickets,
    isLoading,
    isError
  } = ticketApi.endpoints.getTicketDetails.useQuery(eventId!, { skip: !eventId });
  const shadCnTheme = useShadCnTheme();
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (tickets && tickets.tickets?.find(t => t.status === 'TO_BE_MINTED')) {
        dispatch(ticketApi.util.invalidateTags([{ type: 'ticket-details', id: eventId! }]));
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [dispatch, tickets]);

  const revealNft = async (ticketUuid: string, eventUuid: string) => {
    await openTicket(ticketUuid).unwrap();
    setRevealModal(true);
    dispatch(ticketApi.util.invalidateTags([{ type: 'ticket-details', id: eventUuid }]));
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuth) {
    navigate('/my-tickets');
  }

  if (isError) {
    return <UnhandledError />;
  }

  const settings = {
    className: 'slick-center',
    dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i: any) => (
      <Typography
        variant="h6"
        color={i == activeSlide ? (darkTheme ? 'white' : 'black') : darkTheme ? greyScale[800] : greyScale[300]}
      >
        ●
      </Typography>
    ),
    // centerPadding: '60px',
    // beforeChange: (current: number, next: number) => setActiveSlide(next),
    afterChange: (current: number) => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: tickets?.tickets && tickets?.tickets?.length > 2 ? 3 : 1,
          infinite: true,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true,
          centerMode: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: tickets?.tickets && tickets?.tickets?.length > 2 ? 3 : 1,
          infinite: true,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      },

      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      }
    ]
  };

  if (showTransfertPage) {
    return (
      <div className="flex flex-col">
        <Stack direction="row" zIndex="tooltip" position="absolute" left={0} top={0} m={1} alignItems="center">
          <IconButton onClick={() => setShowTransfertPage(false)}>
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography variant="h6" textAlign="center">
            Transférer mes billets
          </Typography>
        </Stack>
        <div className="flex flex-col justify-center">
          <Typography variant="xl" textAlign="center" color={greyScale[500]} mt={12} p={1}>
            Renseignez les informations de la personne à qui vous souhaitez transférer vos billets.
          </Typography>
          <div className="flex flex-col w-full p-4 space-y-2">
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={2}>
              <TextField
                name="lastName"
                variant="outlined"
                placeholder={t('checkout.lastName') ?? ''}
                fullWidth
                InputProps={{
                  className: 'h-12 bg-gray-800 ',
                  startAdornment: <Person className="h-[20px] mr-1"></Person>
                }}
              />
              <TextField
                name="firstName"
                variant="outlined"
                placeholder={t('checkout.firstName') ?? ''}
                fullWidth
                InputProps={{
                  className: 'h-12 bg-gray-800',
                  startAdornment: <Person className="h-[20px] mr-1"></Person>
                }}
              />
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <TextField
                name="email"
                variant="outlined"
                placeholder={t('checkout.mail') ?? ''}
                fullWidth
                InputProps={{
                  className: 'h-12 bg-gray-800',
                  startAdornment: <Mail className="h-[18px] mr-1"></Mail>
                }}
              />
            </Stack>
          </div>
        </div>
        <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }}>
          <Stack direction="row" spacing={3} justifyContent="center" mx={2} my={1.5}>
            <Button
              variant="contained"
              sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
              className="bg-[#181632]"
            >
              <Stack direction="row" justifyContent="center" alignItems="center" minWidth={100} spacing={1}>
                <Typography variant="lg" fontWeight={700} className="leading-10">
                  Annuler
                </Typography>
              </Stack>
            </Button>
            <Button variant="contained" sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}>
              <Stack direction="row" justifyContent="center" alignItems="center" minWidth={100} spacing={1}>
                <Typography variant="lg" fontWeight={700} className="leading-10">
                  Suivant
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Box>
      </div>
    );
  }

  function AppBar() {
    const theme = useTheme();
    console.log(theme.palette.primary);
    return (
      <Stack direction="row" zIndex="tooltip" position="absolute" left={0} top={0} m={1}>
        <Link to="/my-tickets">
          <IconButton>
            <ArrowBackIcon color="primary" />
          </IconButton>
        </Link>
      </Stack>
    );
  }

  if (isAnimationOpen) {
    return (
      <>
        {tickets && (
          <RevealModal
            ticket={tickets.tickets[activeSlide]}
            event={tickets.event}
            openRevealModal={openRevealModal}
            handleCloseRevealModal={() => setAnimationOpen(false)}
          />
        )}
      </>
    );
  }

  // const logout = async () => {
  //   try {
  //     dispatch(authSlice.actions.removeCredentials());
  //     // dispatch(baseApi.util.resetApiState());
  //     localStorage.clear();
  //     await logout();
  //     navigate('/my-tickets');
  //   } catch (error) {}
  // };

  const theme = useTheme();
  const logo = getWebsiteConfig()?.logo;
  const orgaName = getWebsiteConfig()?.name;

  if (!tickets || tickets?.tickets?.length === 0) {
    return (
      <>
        <Typography variant="h6" textAlign="center" color={palette.error} mt={12}>
          {' '}
          {t('myTickets.noTicketFound')}
        </Typography>
        <UnhandledError />
      </>
    );
  }

  const mobileSubHeader = () => (
    <Stack direction="column" pt={6} mt={-5} width="100%">
      <Stack direction="row" justifyContent="center">
        <img src={logo} width={40} />
      </Stack>
      <Stack direction="row" justifyContent="left" alignItems="center" m={3} spacing={1}>
        <Box
          bgcolor={palette.dark2}
          borderRadius={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={0.5}
        >
          <img src={logo} width={20} />
        </Box>
        <Typography
          variant="md"
          fontWeight={700}
          // color={greyScale[50]}
          textAlign="center"
        >
          {orgaName}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="end">
        <Typography
          variant="h4"
          //  color={greyScale[50]}
          textAlign="center"
        >
          {tickets?.event?.title}
        </Typography>
        <Typography
          variant="xl"
          //  color={greyScale[50]}
          textAlign="center"
        >
          &nbsp;{' '}
          {tickets.tickets[activeSlide].nftId === null && (
            <span
              style={{
                backgroundColor: 'orange',
                borderRadius: 100,
                paddingLeft: 6,
                paddingRight: 6,
                paddingBottom: 2,
                fontSize: 13
              }}
            >
              mint
            </span>
          )}
        </Typography>
      </Stack>
      <Typography variant="lg" color="primary" textAlign="center" mt={0.5}>
        {tickets.event.subTitle}
      </Typography>
    </Stack>
  );

  const desktopSubHeader = () => (
    <Stack direction="column" pt={6} mt={3} width="100%" maxWidth={450}>
      <Typography variant="h6" textAlign="left" mt={3}>
        BILLET #{tickets.tickets[activeSlide].nftId} &nbsp;
        {event?.chain === 'CHILIZ' && <img alt="matic" src="/images/chains/chiliz.png" height={16} />}
        {event?.chain === 'POLYGON' && <img alt="matic" src="/images/chains/polygon.png" height={16} />}
      </Typography>
      <Stack direction="row" justifyContent="left" alignItems="center" m={2} ml={0} spacing={1}>
        <Box
          bgcolor={palette.dark2}
          borderRadius={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={0.5}
        >
          <img src={logo} width={20} />
        </Box>
        <Typography
          variant="sm"
          fontWeight={700}
          //  color={greyScale[50]}
          textAlign="left"
        >
          {orgaName}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="left" alignItems="end">
        <Typography
          variant="h5"
          // color={greyScale[50]}
          textAlign="left"
        >
          {tickets?.event?.title}
        </Typography>
        <Typography
          variant="xl"
          // color={greyScale[50]}
          textAlign="left"
        >
          &nbsp;{' '}
          {tickets.tickets[activeSlide].nftId === null && (
            <span
              style={{
                backgroundColor: 'orange',
                borderRadius: 100,
                paddingLeft: 6,
                paddingRight: 6,
                paddingBottom: 2,
                fontSize: 13
              }}
            >
              mint
            </span>
          )}
        </Typography>
      </Stack>
      <Typography variant="md" color="primary" textAlign="left" mt={0.5}>
        {tickets.event.subTitle}
      </Typography>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          fullWidth
          onClick={async () => {
            const encryptedPrivateKey = localStorage.getItem('encryptedPrivateKey') ?? '';
            // if (encryptedPrivateKey !== '') {
            navigate(`/ticket/event/${tickets.event.uuid}`);
            // } else {
            // await logout();
            // }
          }}
          sx={{ borderRadius: 3, height: 48, px: 4, my: 2 }}
        >
          <Stack direction="row" justifyContent="center" alignItems="center" minWidth={100} spacing={1}>
            <QrCodeScannerRoundedIcon />
            <Typography variant="lg" fontWeight={800} className="leading-10">
              {t('myTickets.showMyTickets')}
            </Typography>
          </Stack>
        </Button>
        {import.meta.env.VITE_NODE_ENV !== 'production' && (
          <Stack direction="row" justifyContent="center" alignItems="center" width="100%" mb={3} spacing={1}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setTransferModal(true)}
              sx={{ borderRadius: 3, height: 48, px: 4 }}
            >
              Vendre
              <NorthRoundedIcon />
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setShowTransfertPage(true)}
              sx={{ borderRadius: 3, height: 48, px: 4 }}
            >
              Transférer
              <SyncAltRoundedIcon />
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
  return (
    <Box>
      {AppBar()}
      {!isDesktop && mobileSubHeader()}

      <Grid container justifyContent="center" alignItems="start">
        <Grid
          item
          xs={12}
          md={7}
          alignItems="start"
          justifyContent="start"
          display="flex"
          height="100%"
          pl={isDesktop ? 6 : 0}
        >
          <Box
            width="100%"
            margin="auto"
            sx={
              isDesktop
                ? {
                    mt: 10,
                    bgcolor: shadCnTheme.theme === 'dark' ? '#000' : '',
                    borderRadius: 2,
                    p: 3,
                    border: shadCnTheme.theme === 'dark' ? '1px solid #333333' : ''
                  }
                : {}
            }
            className="ticket-infos-card"
          >
            <Slider {...settings} ref={sliderRef}>
              {tickets.tickets.map((t, i) => (
                <NftCard
                  key={t.uuid}
                  open={() => {
                    // sliderRef?.current?.slickGoTo(0);
                    // setActiveSlide(2);
                    setAnimationOpen(true);
                    revealNft(tickets.tickets[activeSlide].uuid, tickets.event.uuid);
                  }}
                  isActive={activeSlide == i}
                  ticket={t}
                  event={tickets.event}
                />
              ))}
            </Slider>

            {/* <Carousel
              opts={{
                align: 'center',
                loop: true
              }}
              className="w-full ">
              <CarouselContent>
                {tickets.tickets.map((t, i) => (
                  <CarouselItem key={i} className="md:basis-1/2 lg:basis-3/7">
                    <div className="p-1">
                      <NftCard
                        key={t.uuid}
                        open={() => {
                          // sliderRef?.current?.slickGoTo(0);
                          // setActiveSlide(2);
                          setAnimationOpen(true);
                          revealNft(tickets.tickets[activeSlide].uuid, tickets.event.uuid);
                        }}
                        isActive={activeSlide == i}
                        ticket={t}
                        event={tickets.event}
                      />{' '}
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>

              <CarouselPrevious />
              <CarouselNext />
            </Carousel> */}

            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography variant="lg" color={greyScale[500]} textAlign="center" mt={4}>
                {activeSlide + 1} / {tickets.tickets.length}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
          {!isDesktop && (
            <Typography variant="h4" textAlign="center" mt={3}>
              BILLET #{tickets.tickets[activeSlide].nftId} &nbsp;
              {event?.chain === 'CHILIZ' && <img alt="matic" src="/images/chains/chiliz.png" height={16} />}
              {event?.chain === 'POLYGON' && <img alt="matic" src="/images/chains/polygon.png" height={16} />}
            </Typography>
          )}
          <Stack direction="column" justifyContent="center" alignItems="center" mb={8} p={3}>
            {isDesktop && desktopSubHeader()}
            <CategoryCard tickets={tickets} activeSlide={activeSlide} />
            <DetailsCard
              activeSlide={activeSlide}
              tickets={tickets}
              setAnimationOpen={setAnimationOpen}
              revealNft={revealNft}
            />
          </Stack>
        </Grid>
      </Grid>

      {transferModal && (
        <NoTransferModal showModal={transferModal} setShowModal={setTransferModal} isTransfer={isTransfert} />
      )}

      {!isDesktop && (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <Stack direction="row" spacing={3} justifyContent="center" mx={2} my={1.5}>
            <Popover>
              <PopoverTrigger asChild>
                {import.meta.env.NODE_ENV !== 'production' && (
                  <IconButton sx={{ height: 48, width: 48, borderRadius: 4 }}>
                    <MoreVertRoundedIcon />
                  </IconButton>
                )}
              </PopoverTrigger>
              <PopoverContent className="w-80 ">
                <div className="grid gap-4 ">
                  <Box sx={{ backgroundColor: palette.dark2, borderRadius: 4, ml: 1, mb: 2 }}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      p={3}
                      spacing={1}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => setTransferModal(true)}
                        sx={{ borderRadius: 3, height: 48, px: 4 }}
                      >
                        Vendre
                        <NorthRoundedIcon />
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => setShowTransfertPage(true)}
                        sx={{ borderRadius: 3, height: 48, px: 4 }}
                      >
                        Transférer
                        <SyncAltRoundedIcon />
                      </Button>
                    </Stack>
                  </Box>
                </div>
              </PopoverContent>
            </Popover>

            <Button
              variant="contained"
              onClick={async () => {
                // const encryptedPrivateKey = localStorage.getItem('encryptedPrivateKey') ?? '';
                // if (encryptedPrivateKey !== '') {
                navigate(`/ticket/event/${tickets.event.uuid}`);
                // } else {
                //   await logout();
                // }
              }}
              sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
            >
              <Stack direction="row" justifyContent="center" alignItems="center" minWidth={100} spacing={1}>
                <QrCodeScannerRoundedIcon />
                <Typography variant="lg" fontWeight={800} className="leading-10">
                  {t('myTickets.showMyTickets')}
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Paper>
      )}
    </Box>
  );
}

export default TicketDetails;
