import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { Ticket } from '../../../../services/api/ticket';
import { format } from 'date-fns';
import { Event } from '../../../../services/api/event';
import QRCode from 'qrcode';
import { Print } from '@mui/icons-material';

const styles = StyleSheet.create({
  ticket: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 20,
    width: '100%',
    border: '1px solid black'
  },
  header: {
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  details: {
    fontSize: 12,
    lineHeight: 1.5,
    marginBottom: 10
  },
  barcode: {
    marginTop: 10,
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  }
});

interface PdfTicketProps {
  tickets: Ticket[];
  event?: Event;
}

const PdfTicket = ({ tickets, event }: PdfTicketProps) => {
  const ticketsWithUrl = tickets.map(ticket => ({
    ...ticket,
    qrCodeUrl: QRCode.toDataURL(ticket.qrCode)
  }));

  return (
    <Document>
      {ticketsWithUrl.map(ticket => (
        <Page size="A4" style={styles.ticket}>
          <View>
            <Text style={styles.header}>{event?.title}</Text>
            <Text style={styles.details}>
              Ticket N°: {ticket?.nftId}-{ticket?.id}
            </Text>
            <Text style={styles.details}>
              Date: {event?.beginAt && format(new Date(event?.beginAt ?? ''), 'dd/MM/yyyy à HH:mm')}
            </Text>
            <Text style={styles.details}>Lieu: {event?.address?.name}</Text>
            <Text style={styles.details}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{event?.address?.street}, {event?.address?.zipCode}{' '}
              {event?.address?.city}
            </Text>
            <Text style={styles.details}>Categorie: {ticket?.ticketCategory?.name}</Text>
            <View style={styles.barcode}>
              <Image src={ticket.qrCodeUrl} style={{ width: '50%' }} />
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

interface PrintPTicketButtonProps {
  tickets: Ticket[];
  event?: Event;
}

const PrintPTicketButton = ({ tickets, event }: PrintPTicketButtonProps) => {
  return (
    <PDFDownloadLink
      document={<PdfTicket tickets={tickets} event={event} />}
      fileName="billet.pdf"
      className="w-[50%] rounded-xl py-1 font-bold bg-black flex items-center justify-evenly hover:bg-slate-600 cursor-pointer text-white"
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          'chargement...'
        ) : (
          <div className="flex items-center justify-center space-x-1">
            <Print sx={{ width: 20, height: 20 }}></Print> <span>Imprimer</span>
          </div>
        )
      }
    </PDFDownloadLink>
  );
};
export default PrintPTicketButton;
