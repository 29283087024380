/* eslint-disable camelcase */
import baseApi from './base';
import { Address, AddressDetails, Event, Organization } from './event';

/**
 * Model Ticket
 *
 */
export type Ticket = {
  id: number;
  uuid: string;
  qrCode: string;
  nftId: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  isOpened: boolean;
  isScanned: boolean;
  rewardScanned: boolean;
  srcPrice: number;
  finalPrice: number;
  discount: number;
  extReference: string;
  currencyCode: string;
  rarity: string;
  reward: string;
  tokenUri: string;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  eventId: number;
  userId: number;
  ticketCategoryId: number;
  organizationId: number;
  ticketReservationId: number;
  ticketCategory: TicketCategory;
  event: Event;
};

/**
 * Model TicketCategory
 *
 */
export type TicketCategory = {
  id: number;
  uuid: string;
  name: string | null;
  price: number | null;
  maxPerPerson: number | null;
  minPerPerson: number | null;
  quantityAvailable: number | null;
  quantitySold: number | null;
  startSaleDate: Date | null;
  endSaleDate: Date | null;
  range: number | null;
  status: string;
  ticketCategoryId: number | null;
  eventId: number;
  organizationId: number;
};

export interface Seat {
  key: string;
  value: string;
}

export interface TicketEvent {
  uuid: string;
  title: string;
  beginAt: string;
  endAt: string;
  cover: string;
  tickets: Ticket[];
  address: Address;
}

export interface TicketEventDetails {
  event: Event;
  tickets: Ticket[];
}

export enum TicketStatus {
  FREE = 'FREE',
  PENDING = 'PENDING',
  TO_BE_PAID = 'TO_BE_PAID',
  ACQUIRED = 'ACQUIRED',
  CANCELLED = 'CANCELLED',
  CHECKED_IN = 'CHECKED_IN',
  EXPIRED = 'EXPIRED',
  INVALIDATED = 'INVALIDATED',
  RELEASED = 'RELEASED',
  PRE_RESERVED = 'PRE_RESERVED'
}

export interface RevealTicketsForm {
  status: TicketStatus;
}

export type TicketReservation = {
  id: number;
  uuid: string;
  validity: Date;
  status: TicketStatus;
  firstName: string;
  lastName: string;
  email: string;
  userLanguage: string | null;
  srcPrice: number;
  finalPrice: number;
  currencyCode: string | null;
  userId: number;
  promoCodeDiscountId: number | null;
  eventId: number;
  event: Event;
};

interface AddToWalletForm {
  ticketUuid: string;
}

const ticketApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTicketDetails: builder.query<TicketEventDetails, string>({
      query: eventUuid => ({
        url: `/tickets/events/${eventUuid}`,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => [{ type: 'ticket-details', id: arg }]
    }),
    getTicketsEvent: builder.query<TicketEvent[], void>({
      query: () => ({
        url: `/tickets`,
        method: 'GET'
      }),
      providesTags: ['tickets']
    }),
    getTicketByUuid: builder.query<Ticket, string>({
      query: ticketUuid => ({
        url: `/tickets/${ticketUuid}`,
        method: 'GET'
      })
    }),
    getCollectibles: builder.query<
      (Ticket & {
        event: {
          uuid: string;
          beginAt: Date;
          web3Address: string;
          title: string;
        };
      })[],
      void
    >({
      query: () => ({
        url: `/tickets/collectibles`,
        method: 'GET'
      })
    }),
    open: builder.mutation<any, string>({
      query: ticketUuid => ({
        url: `/tickets/${ticketUuid}/open`,
        method: 'PATCH'
      })
    }),
    findPendingReservation: builder.query<TicketReservation[], void>({
      query: () => ({
        url: `/reservations/pending`,
        method: 'GET'
      })
    }),
    addToWallet: builder.mutation<string, AddToWalletForm>({
      query: body => ({
        url: `/tickets/generate-pass`,
        method: 'POST',
        body
      })
    })
  })
});

export default ticketApi;
