import { useState } from 'react';
import { Stack, Typography, Box, IconButton, ButtonBase, useMediaQuery, Link, Button } from '@mui/material';
import greyScale from '../../themes/default/colors/greyscale';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import i18next, { t } from 'i18next';
import ChangeLanguageModal from '../ChangeLanguageModal';
import { languageList } from '../../i18n';
import { useAppSelector } from '../../redux/hooks';
import palette from '../../themes/default/colors/palette';
import DarkModeSwitch from '../DarkModeSwitch';
import LogoButton from '../LogoButton';
import { getWebsiteConfig } from '../../pages/whiteLabel/themeFactory';
import { ArrowDown, ChevronDown } from 'lucide-react';

const Footer = () => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const [changeLanguageModalOpen, setChangeLanguageModalOpen] = useState(false);
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const language = languageList.find(l => l.code === i18next.resolvedLanguage);

  return (
    <footer>
      <ChangeLanguageModal
        changeLanguageModalOpen={changeLanguageModalOpen}
        handleCloseChangeLanguageModal={() => setChangeLanguageModalOpen(false)}
      />
      <Box bgcolor={darkTheme ? palette.dark4 : greyScale[100]} p={3} pt={isDesktop ? 3 : 0.5} width="100%" bottom={0}>
        <Stack direction="column" alignItems="center" justifyContent="center" width="100%" spacing={1.5} mt={2}>
          <Stack
            width="100%"
            direction={isDesktop ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            spacing={2}
          >
            <Stack direction="row" alignItems="center">
              <LogoButton />
              <Typography variant="lg">© 2024 Tickie.</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Button variant="text" onClick={() => setChangeLanguageModalOpen(true)}>
                <p className="font-bold dark:text-white text-black my-2">{`${
                  language?.language
                } (${language?.code.toUpperCase()})`}</p>
                <ChevronDown className="dark:text-white text-black" />
              </Button>
              {/* <ButtonBase>
                <Typography variant="lg" fontWeight={700}>
                  € EUR
                </Typography>
              </ButtonBase> */}
              <DarkModeSwitch />
            </Stack>
            <Stack direction="row">
              <Link href={getWebsiteConfig().social.linkedin}>
                <IconButton>
                  <LinkedInIcon />
                </IconButton>
              </Link>
              <Link href={getWebsiteConfig().social.instagram}>
                <IconButton>
                  <InstagramIcon />
                </IconButton>
              </Link>
              <Link href={getWebsiteConfig().social.twitter}>
                <IconButton>
                  <TwitterIcon />
                </IconButton>
              </Link>
            </Stack>
          </Stack>
          <Stack
            direction={isDesktop ? 'row' : 'column'}
            spacing={1.75}
            pb={isDesktop ? 0 : 6}
            divider={isDesktop ? <p>●</p> : null}
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Link href="https://tickieapp.notion.site/Politique-de-confidentialit-d08f2ac3727b45d896a07510518035fb">
              {t('footer.privacyPolicy')}
            </Link>
            <Link href="https://tickieapp.notion.site/Conditions-g-n-rales-de-vente-c2dcaf87635c441182bab9982d20e426">
              {t('footer.saleConditions')}
            </Link>
            <Link href="https://tally.so/r/3X5gvO">{t('footer.contactUs')}</Link>
            <Link href="https://tickieapp.notion.site/FAQ-3a1ebb1e6b47402a9b5dc9751191b17a">{t('footer.faq')}</Link>
          </Stack>
        </Stack>
      </Box>
    </footer>
  );
};

export default Footer;
