import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import '/fonts/SpaceGrotesk-VariableFont_wght.ttf';
import black from '/images/rarities/black.png';
import gold from '/images/rarities/gold.png';
import bronze from '/images/rarities/bronz.png';
import silver from '/images/rarities/silver.png';
import diamond from '/images/rarities/diamond.png';
import { it } from 'date-fns/locale';

interface reward {
  quantity: number;
  reward: string;
  Rarity: string;
  image: string;
}
interface RewardsProps {
  rewards: reward[]; // You can replace this with the actual type of rewards
  legend: string;
  onClickMintButton: any;
}

const Rewards: React.FC<RewardsProps> = ({ rewards, legend, onClickMintButton }) => {
  const customBreakpoints = {
    custom: '@media (min-width: 1100px)'
  };
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  return (
    <Box
      width={'100%'}
      className="custom-font-text"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        px: isDesktop ? ' 100px' : '10px'
      }}
    >
      <Typography
        className="custom-font-text"
        sx={{
          fontWeight: 'bold',
          color: '#fff',
          textTransform: 'uppercase',
          fontFamily: 'Space Grotesk',
          fontStyle: 'normal',
          marginTop: '120px',
          fontSize: !isDesktop ? '30px' : '40px',
          textAlign: !isDesktop ? 'center' : 'left'
        }}
      >
        Récompenses
      </Typography>
      <br />
      <br />
      <Box
        width={'100%'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          width={'100%'}
          height={'600px'}
          sx={{
            display: 'flex',
            flexDirection: isDesktop ? 'row ' : 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '35px',
            backgroundColor: '#fff',
            fontFamily: 'helvetica'
          }}
        >
          <Box width={'100%'}>
            {' '}
            <Box
              sx={{
                padding: isDesktop ? '50px' : '10px',
                color: 'black'
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: !isDesktop ? '20px' : 'unset',
                  fontFamily: 'Helvetica '
                }}
              >
                Voici les récompenses se cachant dans ces billets souvenir :
              </Typography>
              <br />
              {rewards.map((item: reward, index: number) => {
                return item.quantity ? (
                  <Box
                    height={70}
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: '8px',
                        fontFamily: 'Helvetica '
                        // justifyContent: 'center'
                      }}
                    >
                      <img src={item.image} alt="card" className="w-[25px] mr-1" />
                      {item.Rarity === 'COMMUN' && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <span className=" whitespace-nowrap bg-gradient-to-r sm:text-2xl text-lg from-[#171717] via-[#4D4B49] to-[#171717] text-transparent bg-clip-text font-extrabold">
                            {/* <span className=" whitespace-nowrap bg-gradient-to-r sm:text-2xl text-lg from-[#994A1E] via-[#DB7E49] to-[#994A1E] text-transparent bg-clip-text font-extrabold"> */}
                            x{item.quantity} {item.Rarity}
                          </span>
                          <Typography
                            variant={isSmall ? 'lg' : 'sm'}
                            sx={{
                              fontFamily: 'Helvetica '
                            }}
                          >
                            &nbsp;&nbsp; (rareté : 99%)
                          </Typography>
                        </Box>
                      )}
                      {item.Rarity === 'BRONZE' && (
                        <span className=" whitespace-nowrap bg-gradient-to-r sm:text-2xl text-lg from-[#994A1E] via-[#DB7E49] to-[#994A1E] text-transparent bg-clip-text font-extrabold">
                          x{item.quantity} {item.Rarity}
                        </span>
                      )}
                      {item.Rarity === 'SILVER' && (
                        <span className=" whitespace-nowrap bg-gradient-to-r sm:text-2xl text-lg from-[#9497A0] via-[#D5D1E2] to-[#9497A0] text-transparent bg-clip-text font-bold">
                          x{item.quantity} {item.Rarity}{' '}
                        </span>
                      )}
                      {item.Rarity === 'OR' && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <span className="whitespace-nowrap bg-gradient-to-r sm:text-2xl text-lg from-[#CCA418] via-[#DFD046] to-[#CCA418] text-transparent bg-clip-text font-bold">
                            x{item.quantity} {item.Rarity}
                          </span>
                          <Typography
                            variant={isSmall ? 'lg' : 'sm'}
                            sx={{
                              fontFamily: 'Helvetica '
                            }}
                          >
                            &nbsp;&nbsp; (rareté : 1%)
                          </Typography>
                        </Box>
                      )}
                      {item.Rarity === 'DIAMOND' && (
                        <span className="bg-gradient-to-r sm:text-2xl text-lg from-[#3698F2] via-[#49D2DB] to-[#3698F2] text-transparent bg-clip-text font-bold">
                          x{item.quantity} {item.Rarity}{' '}
                        </span>
                      )}
                      {item.Rarity === 'UNIQUE' && (
                        <span className="bg-gradient-to-r sm:text-2xl text-lg from-[#171717] via-[#4D4B49] to-[#171717] text-transparent bg-clip-text font-bold">
                          x{item.quantity} {item.Rarity}{' '}
                        </span>
                      )}
                    </Box>

                    <Typography
                      variant={isSmall ? 'lg' : 'sm'}
                      sx={{
                        fontFamily: 'Helvetica '
                      }}
                    >
                      {' '}
                      {item.reward}
                    </Typography>
                  </Box>
                ) : null;
              })}

              {/* <Box
                height={70}
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <img src={silver} alt="card" className="sm:w-[25px] w-[20px] mr-1" />

                <span className="bg-gradient-to-r sm:text-2xl text-lg from-[#9497A0] via-[#D5D1E2] to-[#9497A0] text-transparent bg-clip-text font-bold">
                  x60 SILVER
                </span>
                <Typography variant={isSmall ? 'lg' : 'sm'}> &nbsp;Une boisson ouverte à la buvette</Typography>
              </Box>

              <Box
                height={70}
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <img src={gold} alt="card" className="w-[25px] mr-1" />

                <span className="bg-gradient-to-r sm:text-2xl text-lg from-[#CCA418] via-[#DFD046] to-[#CCA418] text-transparent bg-clip-text font-bold">
                  x60 GOLD
                </span>
                <Typography variant={isSmall ? 'lg' : 'sm'}> &nbsp;Une boisson ouverte à la buvette</Typography>
              </Box>

              <Box
                height={70}
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <img src={diamond} alt="card" className="w-[25px] mr-1" />
                <span className="bg-gradient-to-r sm:text-2xl text-lg from-[#3698F2] via-[#49D2DB] to-[#3698F2] text-transparent bg-clip-text font-bold">
                  x60 DIAMOND
                </span>
                <Typography variant={isSmall ? 'lg' : 'sm'}> &nbsp;Une boisson ouverte à la buvette</Typography>
              </Box>

              <Box
                height={70}
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <img src={black} alt="card" className="w-[25px] mr-1" />
                <span className="bg-gradient-to-r sm:text-2xl text-lg from-[#171717] via-[#4D4B49] to-[#171717] text-transparent bg-clip-text font-bold">
                  x60 Unique
                </span>
                <Typography variant={isSmall ? 'lg' : 'sm'}> &nbsp;Une boisson ouverte à la buvette</Typography>
              </Box> */}
              <br />
              {/* <Box
                className="custom-font-text"
                height={45}
                width={!isSmall ? '90%' : 220}
                onClick={onClickMintButton}
                sx={{
                  backgroundColor: '#D7BE34',
                  color: '#fff',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '20px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  fontSize: isSmall ? 17 : 16,
                  margin: isSmall ? 'unset' : 'auto'
                }}
              >
                OBTENIR MAINTENANT
              </Box> */}
              <br />
            </Box>
          </Box>

          <Box
            width={'100%'}
            height={'100%'}
            sx={{
              borderRadius: isDesktop ? '0 35px 35px 0' : '35px 35px 0 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: `url(${legend})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          ></Box>
        </Box>
      </Box>
      <br />
    </Box>
  );
};

export default Rewards;
