import { Stack, Typography } from '@mui/material';
import greyScale from '../../../../themes/default/colors/greyscale';
import { UserSubscription } from '../../../../services/api/subscription';
import { Ticket } from '../../../../services/api/ticket';

interface TicketInformationsProps {
  type: 'TICKET' | 'SUBSCRIPTION';
  ticket?: Ticket;
  subscription?: UserSubscription;
}

const TicketInformations = ({ subscription, type, ticket }: TicketInformationsProps) => {
  if (type === 'TICKET') {
    if (!ticket) return null;
    return (
      <Stack direction="column" justifyContent="center" alignItems="center" width="100%">
        <Typography variant="xl" fontWeight={700} mb={3}>
          {' '}
          #{ticket.nftId}
        </Typography>
        <Typography
          variant={ticket?.firstName.length + ticket.lastName.length > 20 ? 'h5' : 'h4'}
          mt={-2}
          textAlign="center"
          className="text-white"
        >{`${ticket?.firstName} ${ticket?.lastName}`}</Typography>
        <Stack direction="row" justifyContent="space-around" width="100%" pt={3}>
          <Stack>
            <Typography variant="sm" color={greyScale[300]} textAlign={'center'}>
              Catégorie
            </Typography>
            <Typography
              variant={ticket.ticketCategory?.name && ticket.ticketCategory?.name?.length > 15 ? 'sm' : 'lg'}
              fontWeight={700}
              maxWidth={150}
              textAlign="center"
              className="text-white"
            >
              {ticket.ticketCategory?.name}
            </Typography>
          </Stack>
          {/* <Stack>
            <Typography variant="sm" color={greyScale[300]} textAlign={'center'}>
              Tarif
            </Typography>
            <Typography variant="lg" fontWeight={700} textAlign={'center'} maxWidth={150}>
              Tarif Normal
            </Typography>
          </Stack> */}
        </Stack>
        {false ? (
          <Stack direction="row" justifyContent="space-around" width="100%" pt={2}>
            <Stack>
              <Typography variant="sm" color="gray" textAlign={'center'}>
                Tarif
              </Typography>
              <Typography variant="xl" textAlign={'center'} fontWeight={700}>
                H{' '}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="sm" color="gray" textAlign={'center'}>
                Rang
              </Typography>
              <Typography variant="xl" textAlign={'center'} fontWeight={700}>
                4{' '}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="sm" color="gray" textAlign={'center'}>
                Place
              </Typography>
              <Typography variant="xl" textAlign={'center'} fontWeight={700}>
                12{' '}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Typography variant="h5" pt={2} className="text-white">
            Placement Libre
          </Typography>
        )}
      </Stack>
    );
  } else if (type === 'SUBSCRIPTION') {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center" width="100%">
        <Typography variant="xl" fontWeight={700} mb={3} mt={-1}>
          {' '}
          #{subscription?.nftId}
        </Typography>
        <Typography variant="h4">{`${subscription?.firstName} ${subscription?.lastName}`}</Typography>
        <Stack direction="row" justifyContent="space-around" width="100%" pt={4}>
          <Stack>
            <Typography variant="sm" color={greyScale[300]} textAlign={'center'}>
              Catégorie
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="sm" color={greyScale[300]} textAlign={'center'}>
              Tarif
            </Typography>
            <Typography variant="lg" mt={1} fontWeight={700} textAlign={'center'}>
              Tarif Normal
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }
  return null;
};

export default TicketInformations;
