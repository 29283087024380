import { t } from 'i18next';
import {
  Button,
  Stack,
  Box,
  Typography,
  Divider,
  Checkbox,
  Alert,
  Snackbar,
  IconButton,
  useMediaQuery,
  CircularProgress,
  TextField
} from '@mui/material';
import { useState } from 'react';
import google from '../../assets/images/icons/google.png';
import facebook from '../../assets/images/icons/facebook.png';
import apple from '../../assets/images/icons/apple.png';
import appleDark from '../../assets/images/icons/apple-dark.png';
import logo from '../../assets/images/logo/logo-picto.png';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  getIdToken,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
  FacebookAuthProvider,
  signInWithCustomToken,
  UserCredential
} from 'firebase/auth';
import { auth } from '../../services/firebase';
import { FirebaseError } from '@firebase/util';
import { PinInput } from 'react-input-pin-code';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Trans } from 'react-i18next';
import shadows from '../../themes/default/shadows';
import authApi, { SignInType } from '../../services/api/auth';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import palette from '../../themes/default/colors/palette';
import RPI from 'react-phone-input-2';
// import { useWeb3Auth } from '../../services/web3/web3auth';
import greyScale from '../../themes/default/colors/greyscale';
import BackgroundGradient from '../../components/BackgroundGradient';
import AccountExistModal from './components/AccountExistModal';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import CustomPhoneNumber from './components/CustomPhoneNumber';
import { MailOutline } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { getWebsiteConfig } from '../whiteLabel/themeFactory';
import userApi from '@/services/api/user';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PhoneInput2 = RPI.default ? RPI.default : RPI;
interface LoginProps {
  isReservation?: boolean;
  redirectLink?: string;
}

enum CurrentPage {
  default,
  enterPhoneNumber,
  enterPhoneOtp,
  enterMail,
  enterMailOtp
}

function Login(props: LoginProps) {
  const { isReservation } = props;
  const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const appId = getWebsiteConfig()?.id;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const continueButtonStyle = isDesktop ? { mb: 10 } : { position: 'fixed', bottom: 50, left: 0, right: 0, mb: 3 };
  const [getUser] = userApi.endpoints.getUser.useLazyQuery();
  ///
  const theme = useTheme(); // Access the theme object
  const [checked, setChecked] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [pinCode, setPinCode] = useState(['', '', '', '', '', '']);
  const [currentPage, setCurrentPage] = useState<CurrentPage>(
    isReservation ? CurrentPage.enterPhoneNumber : CurrentPage.default
  );
  const [isCheckingAuthCredentials, setCheckingAuthCredentials] = useState(false);
  const [isCheckingPinCode, setCheckingPinCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAccountExistModalOpen, setAccountExistModal] = useState(false);
  const [existingProvider, setExistingProvider] = useState('');

  const [authRequest] = authApi.endpoints.signIn.useMutation();
  const [mailAuthRequest] = authApi.endpoints.signInWithEmail.useMutation();
  const [verifyMailOtpRequest] = authApi.endpoints.verifyMailOtp.useMutation();

  // const { login } = useWeb3Auth();

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const redirectTo = (needToCompleteProfile: boolean) => {
    if (eventId) {
      return null;
    } else if (needToCompleteProfile) {
      return navigate('/complete-profile');
    } else if (
      location.pathname === '/login' ||
      location.pathname === '/profile' ||
      location.pathname === '/my-tickets'
    ) {
      return navigate('/');
    } else {
      return null;
    }
  };

  const cancelOtpVerification = (currentPage: CurrentPage) => {
    setPinCode(['', '', '', '', '', '']);
    setCheckingPinCode(false);
    setCurrentPage(currentPage);
    setErrorSnackbar(false);
  };

  const generateRecaptcha = () => {
    if (window) {
      return ((window as any).recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response: any) => {}
        },
        auth
      ));
    }
  };

  const socialLogin = async (provider: any, type: SignInType) => {
    let result: UserCredential | null = null;
    try {
      setCheckingAuthCredentials(true);
      // isReservation ? null : dispatch(baseApi.util.resetApiState());
      if (type === SignInType.APPLE) {
        provider.addScope('name');
        provider.addScope('email');
      } else if (type === SignInType.FACEBOOK) {
        provider.addScope('public_profile');
        provider.addScope('email');
      }
      result = await signInWithPopup(auth, provider);

      const idToken = await result.user.getIdToken(true);
      const { needToCompleteProfile, secret } = await authRequest({
        idToken,
        type
      }).unwrap();
      setCheckingPinCode(false);
      setCheckingAuthCredentials(false);
      await getUser().unwrap();

      // await login(await result.user.getIdToken(true), secret);

      redirectTo(needToCompleteProfile);
    } catch (error) {
      console.log(error);
      setCheckingAuthCredentials(false);
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/popup-closed-by-user') {
          setErrorMessage(t('firebaseAuthErrors.auth/popup-closed-by-user') ?? '');
          setErrorSnackbar(true);
        } else if (error.code === 'auth/account-exists-with-different-credential') {
          setAccountExistModal(true);
          setExistingProvider((error.customData as any)?._tokenResponse?.verifiedProvider[0].replaceAll('.com', ''));
        } else {
          setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
          setErrorSnackbar(true);
          console.log(error);
        }
      } else {
        setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
        setErrorSnackbar(true);
        console.log(error);
      }
    }
  };

  const sendSmsCode = async () => {
    if (phoneNumber === '' || phoneNumber.length < 10) return;
    generateRecaptcha();
    const appVerifier = (window as any).recaptchaVerifier;
    try {
      setCheckingAuthCredentials(true);
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      (window as any).confirmationResult = confirmationResult;
      setCurrentPage(CurrentPage.enterPhoneOtp);
      setCheckingAuthCredentials(false);
    } catch (error) {
      setCheckingAuthCredentials(false);
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/invalid-phone-number') {
          setErrorMessage(`${t('firebaseAuthErrors.auth/invalid-phone-number') ?? ''} (${phoneNumber})`);
          setErrorSnackbar(true);
          console.log(error);
        } else if (error.code === 'auth/too-many-requests') {
          setErrorMessage(`${t('firebaseAuthErrors.auth/too-many-requests') ?? ''}`);
          setErrorSnackbar(true);
        } else {
          setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
          setErrorSnackbar(true);
          console.log(error);
        }
      } else {
        setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
        setErrorSnackbar(true);
        console.log(error);
      }
    }
  };

  const sendEmailCode = async () => {
    if (email === '') return;
    await mailAuthRequest({ email }).unwrap;
    try {
      setCheckingAuthCredentials(true);
      setCurrentPage(CurrentPage.enterMailOtp);
      setCheckingAuthCredentials(false);
    } catch (error) {
      setCheckingAuthCredentials(false);
      setErrorMessage((error as any)?.toString());
      setErrorSnackbar(true);
      console.log(error);
    }
  };

  const verifyMailOtp = async (otp: string) => {
    if (otp.length === 6 && !isCheckingPinCode) {
      try {
        setCheckingPinCode(true);
        const { customIdToken } = await verifyMailOtpRequest({ email, otp }).unwrap();
        // isReservation ? null : dispatch(baseApi.util.resetApiState());
        const { user } = await signInWithCustomToken(auth, customIdToken);
        const idToken = await getIdToken(user, true);
        const { needToCompleteProfile, secret } = await authRequest({
          idToken,
          type: SignInType.EMAIL
        }).unwrap();
        setCheckingPinCode(false);
        await getUser().unwrap();
        // await login(await getIdToken(user, true), secret);
        redirectTo(needToCompleteProfile);
        setCheckingPinCode(true);
      } catch (error) {
        setCheckingPinCode(false);
        setCheckingAuthCredentials(false);
        setErrorMessage((error as any)?.toString());
        setErrorSnackbar(true);
        console.log(error);
      }
    }
  };

  const verifyPhoneOtp = async (otp: string) => {
    if (otp.length === 6 && !isCheckingPinCode) {
      try {
        setCheckingPinCode(true);
        const confirmationResult = (window as any).confirmationResult;
        const confirm = await confirmationResult.confirm(otp);
        const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
        const { currentUser } = auth;
        if (currentUser) {
          // isReservation ? null : dispatch(baseApi.util.resetApiState());
          const idToken = await getIdToken(currentUser, true);
          const { needToCompleteProfile, secret } = await authRequest({
            idToken,
            type: SignInType.PHONE
          }).unwrap();
          setCheckingPinCode(false);
          await getUser().unwrap();
          redirectTo(needToCompleteProfile);
        } else {
          setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
          setErrorSnackbar(true);
          setCheckingPinCode(false);
        }
        setCheckingPinCode(true);
      } catch (error) {
        setCheckingPinCode(false);
        if (error instanceof FirebaseError) {
          if (error.code === 'auth/invalid-phone-number') {
            setErrorMessage(`${t('firebaseAuthErrors.auth/invalid-phone-number') ?? ''} (${phoneNumber})`);
            setErrorSnackbar(true);
            console.log(error);
          } else if (error.code === 'auth/invalid-verification-code') {
            setErrorMessage(`${t('firebaseAuthErrors.auth/invalid-verification-code') ?? ''}`);
            setErrorSnackbar(true);
          } else if (error.code === 'auth/too-many-requests') {
            setErrorMessage(`${t('firebaseAuthErrors.auth/too-many-requests') ?? ''}`);
            setErrorSnackbar(true);
          } else {
            setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
            setErrorSnackbar(true);
            console.log(error);
          }
        } else {
          setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
          setErrorSnackbar(true);
          console.log(error);
        }
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  if (currentPage == CurrentPage.enterMail) {
    {
      return (
        <>
          {isReservation ? null : <BackgroundGradient />}
          {isReservation ? null : (
            <IconButton onClick={() => setCurrentPage(CurrentPage.default)} sx={{ m: 1 }}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Box display="flex" alignItems="center" justifyContent="center" minHeight={isReservation ? '' : '100vh'}>
            <form>
              <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  severity="error"
                  sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
                >
                  {errorMessage}
                </Alert>
              </Snackbar>

              <Stack direction="column" alignItems="center" spacing={isReservation ? 3 : 6} width="100%">
                <Box width={150} height={150} pl={3} sx={{ display: 'none' }}>
                  <img src={logo} width="100%" height="100%" />
                </Box>
                {isReservation ? (
                  <Typography variant="h5" textAlign="center">
                    {/* TODO to remove  */}
                    {appId == 'SPORSORA'
                      ? 'Rentrez votre numéro de téléphone pour récupérer votre billet'
                      : t('login.connectOrRegisterToReserve')}
                  </Typography>
                ) : (
                  <Typography variant="h5" textAlign="center">
                    {t('login.conexionOrRegister')}
                  </Typography>
                )}
                <Stack direction="column" spacing={3} width="100%" maxWidth={350}>
                  <TextField
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder={t('checkout.mail') ?? ''}
                    fullWidth
                    // error={touched.email && Boolean(errors.email)}
                    // helperText={touched.email && errors.email}
                  />

                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}
                    display={isReservation ? 'none' : 'flex'}
                  >
                    <Checkbox checked={checked} onChange={handleChange} />
                    <Typography variant="xl" color={greyScale[700]} fontWeight={600}>
                      {t('login.rememberMe')}
                    </Typography>
                  </Stack>
                  <Button variant="contained" onClick={sendEmailCode}>
                    {isCheckingAuthCredentials ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : t('login.continue')}
                  </Button>
                  <Divider sx={{ pt: 1, pb: 1 }}>
                    <Typography variant="xl" color={greyScale[600]} fontWeight={600}>
                      {t('login.orContinueWith')}
                    </Typography>
                  </Divider>
                  <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button
                      variant="socialLogin"
                      startIcon={<img src={facebook} style={{ marginRight: -12 }} />}
                      onClick={() => socialLogin(new FacebookAuthProvider(), SignInType.FACEBOOK)}
                    />
                    <Button
                      variant="socialLogin"
                      startIcon={<img src={google} style={{ marginRight: -12 }} />}
                      onClick={() => socialLogin(new GoogleAuthProvider(), SignInType.GOOGLE)}
                    />
                    <Button
                      variant="socialLogin"
                      startIcon={<img src={darkTheme ? appleDark : apple} style={{ marginRight: -12 }} />}
                      onClick={() => socialLogin(new OAuthProvider('apple.com'), SignInType.APPLE)}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <div id="recaptcha-container"></div>
            </form>
          </Box>
        </>
      );
    }
  }

  if (currentPage == CurrentPage.default) {
    return (
      <>
        <AccountExistModal
          provider={existingProvider}
          isAccountExistModalOpen={isAccountExistModalOpen}
          handleAccountExistModal={() => setAccountExistModal(false)}
        >
          <>
            {existingProvider == 'facebook' && (
              <Button
                variant="socialLogin"
                startIcon={<img src={facebook} />}
                onClick={() => socialLogin(new FacebookAuthProvider(), SignInType.FACEBOOK)}
              >
                {t('login.continueWithFacebook')}
              </Button>
            )}
            {existingProvider == 'google' && (
              <Button
                variant="socialLogin"
                startIcon={<img src={google} />}
                onClick={() => socialLogin(new GoogleAuthProvider(), SignInType.GOOGLE)}
              >
                {t('login.continueWithGoogle')}
              </Button>
            )}
            {existingProvider == 'apple' && (
              <Button
                variant="socialLogin"
                startIcon={<img src={darkTheme ? appleDark : apple} />}
                onClick={() => socialLogin(new OAuthProvider('apple.com'), SignInType.APPLE)}
              >
                {t('login.continueWithApple')}
              </Button>
            )}
          </>
        </AccountExistModal>
        {isReservation ? null : <BackgroundGradient />}
        {isReservation ? null : (
          <IconButton onClick={() => navigate('/')} sx={{ m: 1 }}>
            <ArrowBackIcon />
          </IconButton>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={isReservation ? '' : '90vh'}
          width="100%"
        >
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="error"
              sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
          <Stack direction="column" alignItems="center" spacing={4}>
            {isReservation ? null : (
              <Box width={150} height={150} pl={3}>
                <img src={logo} width="100%" height="100%" />
              </Box>
            )}

            {isReservation ? (
              <Typography variant="h5" textAlign="center" p={3}>
                {appId == 'SPORSORA'
                  ? 'Rentrez votre numéro de téléphone pour récupérer votre billet'
                  : t('login.connectOrRegisterToReserve')}
              </Typography>
            ) : (
              <Typography variant="h5" textAlign="center">
                {t('login.conexionOrRegister')}
              </Typography>
            )}
            <Stack direction="column" spacing={2}>
              <Button
                variant="socialLogin"
                startIcon={<img src={facebook} />}
                onClick={() => socialLogin(new FacebookAuthProvider(), SignInType.FACEBOOK)}
              >
                {t('login.continueWithFacebook')}
              </Button>
              {/* <Button
                variant="socialLogin"
                startIcon={<MailOutline />}
                onClick={() => setCurrentPage(CurrentPage.enterMail)}
              >
                {t('login.continueWithEmail')}
              </Button> */}
              <Button
                variant="socialLogin"
                startIcon={<img src={google} />}
                onClick={() => socialLogin(new GoogleAuthProvider(), SignInType.GOOGLE)}
              >
                {t('login.continueWithGoogle')}
              </Button>
              <Button
                variant="socialLogin"
                startIcon={<img src={darkTheme ? appleDark : apple} />}
                onClick={() => socialLogin(new OAuthProvider('apple.com'), SignInType.APPLE)}
              >
                {t('login.continueWithApple')}
              </Button>
              <Divider sx={{ pt: 1, pb: 1 }}>
                <Typography variant="xl" color={greyScale[700]}>
                  {t('login.or')}
                </Typography>
              </Divider>
              <Button variant="contained" onClick={() => setCurrentPage(CurrentPage.enterPhoneNumber)}>
                {isCheckingAuthCredentials ? (
                  <CircularProgress sx={{ color: '#FFFFFF' }} />
                ) : (
                  t('login.continueWithPhoneNumber')
                )}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  }

  if (currentPage == CurrentPage.enterPhoneOtp || currentPage == CurrentPage.enterMailOtp) {
    return (
      <>
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={isReservation ? 'center' : 'start'}
          spacing={1}
          p={1}
        >
          <IconButton
            onClick={() =>
              cancelOtpVerification(
                currentPage == CurrentPage.enterPhoneOtp ? CurrentPage.enterPhoneNumber : CurrentPage.enterMail
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">
            {currentPage == CurrentPage.enterPhoneOtp ? t('login.checkYourPhoneNumber') : t('login.checkYourEmail')}
          </Typography>
        </Stack>
        <Box height={isReservation ? 0 : 64} />
        <Stack direction="column" alignItems="center" minHeight={isReservation ? '' : '100vh'} m={3} spacing={4}>
          <Typography variant="xl" textAlign="center">
            {currentPage == CurrentPage.enterPhoneOtp ? (
              <Trans i18nKey="login.enterTheCodeSentTo" values={{ phoneNumber }} components={[<b />]} />
            ) : (
              <Trans i18nKey="login.enterTheCodeSentToMail" values={{ email }} components={[<b />]} />
            )}
          </Typography>
          <PinInput
            values={pinCode}
            autoFocus
            onChange={(value, index, values) => {
              setPinCode(values);
              if (currentPage == CurrentPage.enterMailOtp) {
                verifyMailOtp(values.join(''));
              } else {
                verifyPhoneOtp(values.join(''));
              }
            }}
            placeholder=""
            type="number"
            autoComplete="one-time-code"
            inputStyle={{
              height: isDesktop ? 64 : 50,
              width: isDesktop ? 64 : 50,
              marginRight: 8,
              borderColor: 'transparent',
              backgroundColor: darkTheme ? greyScale[900] : greyScale[200],
              color: darkTheme ? 'white' : greyScale[900]
            }}
            showState={false}
          />
          <Box p={3} sx={continueButtonStyle} zIndex={1000}>
            <Stack direction="column" justifyContent="center" alignItems="center" width="100%">
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  if (currentPage == CurrentPage.enterMailOtp) {
                    verifyMailOtp(pinCode.join(''));
                  } else {
                    verifyPhoneOtp(pinCode.join(''));
                  }
                }}
                sx={{
                  //  boxShadow: theme.shadows.primaryButton,
                  width: { xs: '100%', md: 200 }
                }}
              >
                {isCheckingPinCode ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : t('reservation.continue')}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <AccountExistModal
          provider={existingProvider}
          isAccountExistModalOpen={isAccountExistModalOpen}
          handleAccountExistModal={() => setAccountExistModal(false)}
        >
          <>
            {existingProvider == 'facebook' && (
              <Button
                variant="socialLogin"
                startIcon={<img src={facebook} />}
                onClick={() => socialLogin(new FacebookAuthProvider(), SignInType.FACEBOOK)}
              >
                {t('login.continueWithFacebook')}
              </Button>
            )}
            {existingProvider == 'google' && (
              <Button
                variant="socialLogin"
                startIcon={<img src={google} />}
                onClick={() => socialLogin(new GoogleAuthProvider(), SignInType.GOOGLE)}
              >
                {t('login.continueWithGoogle')}
              </Button>
            )}
            {existingProvider == 'apple' && (
              <Button
                variant="socialLogin"
                startIcon={<img src={darkTheme ? appleDark : apple} />}
                onClick={() => socialLogin(new OAuthProvider('apple.com'), SignInType.APPLE)}
              >
                {t('login.continueWithApple')}
              </Button>
            )}
          </>
        </AccountExistModal>
        {isReservation ? null : <BackgroundGradient />}
        {isReservation ? null : (
          <IconButton onClick={() => setCurrentPage(CurrentPage.default)} sx={{ m: 1 }}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Box display="flex" alignItems="center" justifyContent="center" minHeight={isReservation ? '' : '100vh'}>
          <form>
            <Snackbar
              open={openErrorSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity="error"
                sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>

            <Stack
              direction="column"
              alignItems="center"
              spacing={isReservation ? 3 : 6}
              width="100%"
              p={isReservation ? 3 : 0}
            >
              <Box width={150} height={150} pl={3} sx={{ display: 'none' }}>
                <img src={logo} width="100%" height="100%" />
              </Box>
              {isReservation ? (
                <Typography variant="h5" textAlign="center">
                  {appId == 'SPORSORA'
                    ? 'Rentrez votre numéro de téléphone pour récupérer votre billet'
                    : t('login.connectOrRegisterToReserve')}
                </Typography>
              ) : (
                <Typography variant="h5" textAlign="center">
                  {t('login.conexionOrRegister')}
                </Typography>
              )}
              <Stack direction="column" spacing={3} width="100%" maxWidth={350} zIndex={5}>
                <form>
                  <Stack direction="column" spacing={3}>
                    <PhoneInput
                      defaultCountry="FR"
                      inputComponent={CustomPhoneNumber}
                      value={phoneNumber}
                      onChange={setPhoneNumber as any}
                      autoComplete="tel"
                      style={{
                        border: `1px solid ${darkTheme ? palette.dark2 : greyScale[200]}`,
                        paddingLeft: 16,
                        height: 62,
                        width: '100%',
                        backgroundColor: darkTheme ? palette.dark2 : greyScale[50],
                        borderRadius: 16,
                        fontFamily: 'Urbanist',
                        fontSize: '1.115rem',
                        fontWeight: 600,
                        marginBottom: isReservation ? 16 : 0
                      }}
                    />

                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={0.5}
                      display={isReservation ? 'none' : 'flex'}
                    >
                      <Checkbox checked={checked} onChange={handleChange} />
                      <Typography variant="xl" color={greyScale[700]} fontWeight={600}>
                        {t('login.rememberMe')}
                      </Typography>
                    </Stack>
                    <Button variant="contained" onClick={sendSmsCode}>
                      {isCheckingAuthCredentials ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : t('login.continue')}
                    </Button>
                  </Stack>
                </form>
                <Divider sx={{ pt: 1, pb: 1 }}>
                  <Typography variant="xl" color={greyScale[600]} fontWeight={600}>
                    {t('login.orContinueWith')}
                  </Typography>
                </Divider>
                <Stack direction="row" justifyContent="center" spacing={2}>
                  <Button
                    variant="socialLogin"
                    startIcon={<img src={facebook} style={{ marginRight: -12 }} />}
                    onClick={() => socialLogin(new FacebookAuthProvider(), SignInType.FACEBOOK)}
                  />
                  <Button
                    variant="socialLogin"
                    startIcon={<img src={google} style={{ marginRight: -12 }} />}
                    onClick={() => socialLogin(new GoogleAuthProvider(), SignInType.GOOGLE)}
                  />
                  <Button
                    variant="socialLogin"
                    startIcon={<img src={darkTheme ? appleDark : apple} style={{ marginRight: -12 }} />}
                    onClick={() => socialLogin(new OAuthProvider('apple.com'), SignInType.APPLE)}
                  />
                </Stack>
              </Stack>
            </Stack>

            <div id="recaptcha-container"></div>
          </form>
        </Box>
      </>
    );
  }
}

export default Login;
