import Header from '../components/Header';
import Hero from '../components/Hero';
import Rewards from '../components/Rewards';
import Main from '../components/Main';
import Faq from '../components/Faq';
import { Alert, Box, Snackbar, ThemeProvider, useMediaQuery } from '@mui/material';
import Contact from '../components/Contact';
import data from './nice.json';
import Footer from '../components/Footer';
import { useAppSelector } from '../../../redux/hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ticketApi from '../../../services/api/ticket';
import ticketCategoriesApi from '../../../services/api/ticket-category';
import paymentApi, { PurchaseType } from '../../../services/api/payment';
import { useState } from 'react';
import userApi from '../../../services/api/user';
import authSlice from '../../../services/auth';
import LoginModal from '../components/LoginModal';
import { getClubThemeFromHostname } from '../../whiteLabel/themeFactory';

function LesDeterminesHome() {
  const eventId = 'f045de70-0a71-4860-a2d5-1907aba1c369';
  const ticketCategoryUuid = '66847fbd-5210-46c9-a44e-92a585ba699b';
  //
  const { isAuth } = useAppSelector(state => state.authSlice);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: ticketsEvent,
    isLoading: isLoadingTickets,
    isError
  } = ticketApi.endpoints.getTicketsEvent.useQuery(undefined, { refetchOnMountOrArgChange: true });
  const [createInvitReservation, { data: paymentIntent }] = paymentApi.endpoints.createInvitReservation.useMutation();
  const [canBuyTickets] = ticketCategoriesApi.endpoints.canBuyTickets.useMutation();
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { data: user } = userApi.endpoints.getUser.useQueryState();

  const findDtrTicket = ticketsEvent?.find(ticket => ticket.title === 'DTR Cup 2023');

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  // const logout = async () => {
  //   try {
  //     dispatch(authSlice.actions.removeCredentials());
  //     // dispatch(baseApi.util.resetApiState());
  //     localStorage.clear();
  //     await logout();
  //   } catch (error) {}
  // };

  const handleSubmit = async () => {
    // if (!checked) {
    //   setCheckboxError(true);
    //   return;
    // }
    if (!user?.uuid) {
      return;
    }
    // if (user?.publicKey === null || user?.publicKey === undefined || user?.publicKey === '') {
    //   await logout();
    //   return;
    // }

    // e.preventDefault();
    // formik.submitForm();
    // if (!formik.isValid) {
    //   return;
    // }

    try {
      setIsLoading(true);

      await canBuyTickets({
        eventUuid: eventId ?? '',
        categories: [{ categoryUuid: ticketCategoryUuid, quantity: 1 }]
      }).unwrap();

      await createInvitReservation({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        eventUuid: eventId ?? '',
        promoCode: undefined,
        finalPrice: 0,
        srcPrice: 0,
        purchaseType: PurchaseType.Ticket,
        userUuid: user?.uuid,
        places: [{ categoryUuid: ticketCategoryUuid, quantity: 1 }],
        status: 'COMPLETE',
        subscription: undefined
      }).unwrap();
      setIsLoading(false);
      navigate(`/payment-success/${eventId}`);
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.data.message);
      setErrorSnackbar(true);
    }

    setIsLoading(false);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <Box>
      <ThemeProvider theme={getClubThemeFromHostname(false)}>
        <LoginModal handleCloseLoginModal={handleCloseLoginModal} openLoginModal={openLoginModal} />
      </ThemeProvider>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Header logo={data.logo} />
      <Hero
        title={data.title}
        subtitle={data.subtitle}
        image={data.image}
        mintLoading={isLoading}
        onClickMintButton={() => {
          if (!isAuth) {
            handleOpenLoginModal();
          } else {
            if (!findDtrTicket) {
              handleSubmit();
            } else {
              console.log('oo');
            }
          }
        }}
        showMintButton={!findDtrTicket}
      />
      <Rewards
        rewards={data.rewards}
        legend={data.legend}
        onClickMintButton={() => {
          if (!isAuth) {
            handleOpenLoginModal();
          } else {
            if (!findDtrTicket) {
              handleSubmit();
            } else {
              console.log('oo');
            }
          }
        }}
      />
      <Main
        steps={data.steps}
        onLogin={() => {
          if (!isAuth) {
            handleOpenLoginModal();
          }
        }}
        onClickMintButton={() => {
          if (!isAuth) {
            handleOpenLoginModal();
          } else {
            if (!findDtrTicket) {
              handleSubmit();
            } else {
              console.log('oo');
            }
          }
        }}
      />
      {/* <Events /> */}
      <Contact />
      <Faq faq={data.faq} />
      <Footer logo={data.logo} />
    </Box>
  );
}

export default LesDeterminesHome;
