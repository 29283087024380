import { Typography, Stack, useMediaQuery, Button } from '@mui/material';
import { t } from 'i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { Event, HomeEvent } from '../../../../services/api/event';
import greyScale from '../../../../themes/default/colors/greyscale';
import PromoChipTag from '../../../Reservation/components/PromoChipTag';
import { Subscription } from '../../../../services/api/subscription';
import TicketIcon from '../../../../assets/images/icons/ticket';
import { useParams, useSearchParams } from 'react-router-dom';
import shadows from '@/themes/default/shadows';
import { ArrowRight } from 'lucide-react';
import { Row } from '@/components/ui/row';
import AppleIcon from '@/assets/images/icons/apple';
import { Column } from '@/components/ui/column';
import { LockRounded } from '@mui/icons-material';
import PromoCodeInput from '@/pages/Reservation/components/PromoCodeInput';

interface ProceedPaymentCardProps {
  event?: Event;
  subscription?: Subscription;
  isEventAvailable: boolean;
  goToCheckout: () => void;
  showButtons?: boolean;
}

const ProceedPaymentCard = (props: ProceedPaymentCardProps) => {
  const { tickets: selectedTickets, finalPrice, srcPrice } = useAppSelector(app => app.shoppingCartSlice);
  const { event, isEventAvailable, goToCheckout, showButtons } = props;
  let [urlParams, setUrlParams] = useSearchParams();
  const isRewardFromUrl = urlParams.get('isReward');
  const { eventId } = useParams();

  if (!event) {
    return null;
  }

  return (
    <Column className={`checkout-card p-4 md:max-w-[420px] w-full h-min relative`}>
      <p className="text-xl font-bold mt-3">Résumé de votre commande</p>
      <Row className="h-3" />
      {/* TODO need to be removed after sporsora */}
      {!isRewardFromUrl && eventId !== '33536222-5011-412e-93ef-80caea554ba6' && <PromoCodeInput />}
      <div className="mt-8 px-2">
        {selectedTickets?.map((ticket, index) => (
          <div key={ticket.categoryUuid + index}>
            <div className="flex flex-row min-w-full">
              <span className="font-bold text-lg whitespace-nowrap w-max text-gray-300">
                {ticket.quantity} x<TicketIcon size="16" className={`ml-1 mr-2  w-[16px] text-indigo-500`} />
              </span>
              <div className="flex-1">
                <Typography variant="md" color={greyScale[500]} alignItems="center" display="flex">
                  {' '}
                  {` ${ticket.categoryName == null ? '' : `${ticket.categoryName}`} 
            `}
                </Typography>
              </div>
              <div className="flex justify-end">
                {!isRewardFromUrl && (
                  <Typography
                    variant="lg"
                    color={ticket.promo ? greyScale[800] : greyScale[600]}
                    fontWeight={600}
                    sx={{ textDecoration: ticket.promo ? 'line-through' : 'none' }}
                  >{`${Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(ticket.srcPrice * ticket.quantity)}`}</Typography>
                )}
              </div>
            </div>
            {ticket.promo && (
              <Stack direction="row" justifyContent="space-between" mb={1}>
                <PromoChipTag promo={ticket.promo} quantity={ticket.quantity} srcPrice={ticket.srcPrice} />
                {!isRewardFromUrl && (
                  <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(ticket.finalPrice * ticket.quantity)}`}</Typography>
                )}
              </Stack>
            )}
          </div>
        ))}
        <div className="relative overflow-visible h-10 my-4">
          <div
            className={`w-9 h-9  dark:bg-dark1 rounded-full absolute top-1/2  transform -translate-y-1/2  -left-11 flex ${
              showButtons ? 'md:hidden' : ''
            }`}
          ></div>
          <div
            className={`w-9 h-9 dark:bg-dark1 rounded-full absolute top-1/2  transform -translate-y-1/2  -right-11 flex ${
              showButtons ? 'md:hidden' : ''
            }`}
          ></div>
          <div
            style={{
              border: 'none',
              borderTop: '4px dashed #0b0c0f',
              width: '100%',
              position: 'absolute'
            }}
            className="absolute top-1/2  transform -translate-y-1/2  "
          />
        </div>

        {selectedTickets.length > 0 ? (
          <Column>
            <Stack direction="row" justifyContent="space-between" mt={2}>
              <Typography variant="md" color={greyScale[500]}>
                Sous total
              </Typography>
              {!isRewardFromUrl && (
                <Typography
                  variant="lg"
                  color={greyScale[700]}
                  fontWeight={500}
                  sx={{
                    textDecoration: selectedTickets.find(e => e.promo) ? 'line-through' : 'none'
                  }}
                >{`${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(srcPrice)}`}</Typography>
              )}
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="md" color={greyScale[500]}>
                Frais de service
              </Typography>
              {!isRewardFromUrl && (
                <Typography variant="lg" color={greyScale[700]} fontWeight={500}>{`${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(0)}`}</Typography>
              )}
            </Stack>
            {event.settings?.useInsurances && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="md" color={greyScale[500]}>
                  Assurance annulation
                </Typography>
                {!isRewardFromUrl && (
                  <Typography variant="lg" color={greyScale[700]} fontWeight={500}>{`${Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(0)}`}</Typography>
                )}
              </Stack>
            )}
            {/* <Stack direction="row" justifyContent="space-between">
              <Typography variant="md" color={greyScale[500]}>
                TVA 5,5%
              </Typography>
              {!isRewardFromUrl && (
                <Typography variant="lg" color={greyScale[700]} fontWeight={500}>{`${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(srcPrice * 0.055)}`}</Typography>
              )}
            </Stack> */}
            <Stack direction="row" justifyContent="space-between" mt={2}>
              <Typography variant="lg" color={greyScale[500]} fontWeight={700}>
                {t('checkout.total')}
              </Typography>
              {!isRewardFromUrl && (
                <Typography
                  variant="lg"
                  color={greyScale[500]}
                  fontWeight={600}
                  sx={{
                    textDecoration: selectedTickets.find(e => e.promo) ? 'line-through' : 'none'
                  }}
                >{`${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(srcPrice)}`}</Typography>
              )}
            </Stack>
            {selectedTickets.find(e => e.promo) && (
              <Stack direction="row" justifyContent="space-between" mt={2}>
                <Typography variant="md" color={greyScale[500]}></Typography>
                {!isRewardFromUrl && (
                  <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(finalPrice)}`}</Typography>
                )}
              </Stack>
            )}
          </Column>
        ) : (
          <div className="flex flex-col space-y-6 justify-center items-center">
            <img src="/images/shop.png" className="w-24" />
            <Typography variant="lg" color={greyScale[400]}>
              Sélectionnez un billet pour continuer
            </Typography>
          </div>
        )}
        <Row className="mt-4" />
        <div>
          <div className={showButtons ? 'hidden md:flex md:flex-col mt-5' : 'hidden'}>
            <Button
              variant="gradient"
              fullWidth
              disabled={!isEventAvailable}
              onClick={goToCheckout}
              sx={{
                height: 58,
                boxShadow: shadows.primaryButton,
                width: { xs: '100%', md: '100%' }
              }}
            >
              {isEventAvailable ? 'Procéder au paiement' : t('reservation.noTicketsAvailable')}&nbsp;
              <ArrowRight />
            </Button>
            {import.meta.env.VITE_NODE_ENV !== 'production' && (
              <Button
                variant="contained"
                fullWidth
                disabled={!isEventAvailable}
                onClick={goToCheckout}
                sx={{
                  backgroundColor: 'black',
                  height: 58,
                  boxShadow: shadows.primaryButton,
                  width: { xs: '100%', md: '100%' },
                  border: '1px solid black'
                }}
                className="border-1 border-dark3 rounded-2xl mt-2"
              >
                <div className="text-white flex justify-center items-center">
                  {isEventAvailable ? 'Paiement express avec' : t('reservation.noTicketsAvailable')}&nbsp;{' '}
                  <AppleIcon className="text-white h-6 w-6" /> <span className="ml-1 mt-1 font-extrabold">Pay</span>
                </div>
              </Button>
            )}
          </div>
          <Column className="flex items-center mt-8 mb-4">
            <Typography variant="md" color={greyScale[500]} fontWeight={600}>
              <LockRounded className="-mb-1" /> Paiement sécurisé par 3D secure
            </Typography>
            <img src="/images/secure-payment.png" className="w-full mt-4 max-w-[400px]" />
          </Column>
        </div>
      </div>
    </Column>
  );
};

export default ProceedPaymentCard;
